<template>
  <div 
    class="callActivity-Item-v2-container"
    :class="[
      call.status=='Dialed' ? 'Right' : 'Left',
      {
        'missedCall': call.status=='Missed',
        'recievedCall': call.status=='Received',
        'dialledCall': call.status=='Dialed',
      }
    ]" 
  >
    <div class="callActivity-Item-v2-inner-top">
      <div class="icon-container">
        <vb-icon v-if="call.status=='Missed'" v-b-popover.hover.top="'Missed Audio Call'" icon="CallActivityV2-missedCall-icon" height="55.922" width="55.922"/>
        <vb-icon v-else-if="call.status=='Received'" v-b-popover.hover.top="'Received Audio Call'" icon="CallActivityV2-receivedCall-icon" height="55.922" width="55.922"/>
        <vb-icon v-else v-b-popover.hover.top="'Dialed Audio Call'" icon="CallActivityV2-dialledCall-icon" height="55.922" width="55.922"/>
      </div>
      <div class="user-info">
        <small v-if="call.is_out_of_hours">Business hour</small>
        <template v-if="call.status=='Dialed'">
          <template v-if="call.dst_type=='Number'">
            <div class="user-name">{{ call.dst_number | number_formater }}</div>
          </template>
          <template v-else>
            <div class="user-name">{{ call.dst_name }}</div>
            <div class="user-number">{{ call.dst_extn }}</div>
          </template>
          <div v-if="!getIsMobile" class="via-text">
            <template v-if="call.dst_type=='Number'">via {{call.src_number}}</template>
            <!-- <template v-else>{{call.src_name}}</template> -->
            <!-- {{call.src_name}} -->
          </div>
        </template>
        <template v-else>
          <template v-if="call.src_type=='Number'">
            <div class="user-name">{{ call.src_number | number_formater }}</div>
          </template>
          <template v-else>
            <div class="user-name">{{ call.src_name }}</div>
            <div class="user-number">{{ call.src_extn }}</div>
          </template>
          <div v-if="!getIsMobile" class="via-text">
            <template v-if="call.dst_type=='Number'">{{call.dst_number | number_formater}}</template>
            <template v-else-if="call.dst_number">called {{call.dst_number | number_formater}} ({{call.dst_name}})</template>
            <template v-else>{{call.dst_name}}</template>
          </div>
        </template>
      </div>
      <div class="middle-section">
        <span class="middle-section-text">
          
        </span>
      </div>
      <div class="callLogIcons-container">
        <template v-if="condition.actions">
          <template v-if="!assignedData">
            <!-- call -->
            <span class="mr-8px">
              <vb-icon v-b-tooltip.hover title="Audio call" icon="call-callLog-icon" width="35px" height="35px" @click="calling()"/>
            </span>
          </template>
          <!-- call info -->
          <span class="mr-8px" v-if="!assignedData">
            <vb-icon icon="callInfo-callLog-icon" width="35px" height="35px"
              v-if="!assignedData" @click="$emit('onClickInfo1')"
              v-b-tooltip.hover :title="`Call info`"
            />
          </span>
          <!-- add contacts -->
          <span class="mr-8px" v-if="!assignedData && call.status=='Dialed' ? call.dst_type=='Number' && !call.dst_name : call.src_type=='Number' && !call.src_name" >
            <vb-icon v-b-tooltip.hover title="Add to contacts" icon="addContact-callLog-icon" width="35px" height="35px"
              @click="$store.state.global_conditions.add_addressbooks = call.status=='Dialed' ? call.dst_number : call.src_number"
            />
          </span>
          <!-- assign task -->
          <span class="mr-8px" v-if="!isTodoAdded && !call.task">
            <vb-icon icon="assignTask-callLog-icon" width="35px" height="35px"
              v-b-tooltip.hover title="Add to tasks"
              @click="addTodoTask()"
            />
          </span>
          <!-- recording -->
          <span v-if="call.soundFile">
            <vb-icon @click="$emit('click-play',{ soundFile: call.soundFile })" icon="playSound-callLog-icon" width="35px" height="35px" v-b-tooltip.hover title="Call recording" />
          </span>
          <!-- ai transcription -->
          <span v-if="call.soundFile">
            <vb-icon class="aiTranscript"  icon="ai-brain-icon" width="20px" height="18px" @click="$emit('openOnlyTranscriptModal')" v-b-tooltip.hover title="AI transcript" />
          </span>
        </template>
      </div>
      <div class="last-section">
        <div class="time">
          <span>{{ parseInt(call.date) | filter_date_current(null,{ unix: true, formate: 'h:mm a' }) }}</span>
          <b-button v-if="condition.assign" @click="$emit('assign')">
            Assign
          </b-button>
          <slot name="more">
            <b-dropdown v-if="condition.actions" varient="link" no-caret class="ico-filter" menu-class="w-fit-content-imp">
              <template #button-content>
                <b-icon icon="three-dots" variant="#393939" font-scale="1.4"/>
              </template>
              <b-dropdown-item 
                v-if="!assignedData && call.status=='Dialed' ? call.dst_type=='Number' && !call.dst_name : call.src_type=='Number' && !call.src_name" 
                @click="$store.state.global_conditions.add_addressbooks = call.status=='Dialed' ? call.dst_number : call.src_number"
              >
                <vb-icon icon="dialer-addToContact-icon" class="dialer-addToContact-icon" width="16.245" height="15.004" />
                <span class="ml-3">Add contact</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="!assignedData" @click="calling()">
                <b-icon icon="telephone" font-scale="0.96"/>
                <span class="ml-3">Call</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="!assignedData" @click="$emit('onClickInfo1')">
                <vb-icon icon="dialer-callinfo-icon" width="15.7" height="15.701" class="callInfoIcon" />
                <span class="ml-3">Call info</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="assignedData && call.status=='Missed'" @click="$emit('clickAssignDetail')">
                <vb-icon icon="dialer-callinfo-icon" width="15.7" height="15.701" class="callInfoIcon" />
                <span class="ml-3">Assigned detail</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="!isTodoAdded && !call.task" @click="addTodoTask()">
                <vb-icon icon="dialer-callinfo-icon" width="15.7" height="15.701" class="callInfoIcon" />
                <span class="ml-3">Add to tasks</span>
              </b-dropdown-item>
            </b-dropdown>
          </slot>
        </div>
        <div class="duration">{{ call.bill_sec }}</div>
      </div>
    </div>
    <div class="callActivity-Item-v2-inner-2nd">
      <template v-if="tags_note_show">
        <!-- tag -->
        <div v-if="condition.tag" class="tags-container">
          <div v-for="tag in tags" :key="tag.id" class="dialer-call-prefix tag">
            <small v-vb-tag-color="tag.tag.colour">
              <span v-vb-tag-color.text="tag.tag.text_color">{{ tag.tag.tag }}</span>
            </small>
          </div>
        </div>
        <!-- note -->
        <span v-if="condition.note && note" class="dialer-call-prefix note">{{ note }}</span>
      </template>
      <!-- sms -->
      <span v-if="condition.message" class="dialer-call-prefix messageText">{{ call.body || "" }}</span>
    </div>
    <p class="extraInfoInCallLogs mb-0 w-100" v-if="call.transcription_text">
      <span style="font-weight: 800;">Call transcript :</span> 
      {{ call.transcription_text }}
    </p>
    <p v-if="GET_LOCAL_SETTING_DASHBOARD.design.transfer_log" class="extraInfoInCallLogs mb-0 w-100 ">
      <!-- :id="`popover-${call.callerid}`" -->
      <template v-if="call.forward_from_type || call.forward_to_type">
        <!-- forward -->
        <vb-icon 
          icon="callLogs-forward-icon"
          v-b-popover.hover="'Forward'" 
          width="14.61px" height="15.595px"
        />
        <div v-if="call.forward_from_type" class="d-flex align-items-center">
          <div>from</div>
          <div v-if="call.forward_from_type == 'Number'">{{ call.forward_from_number | number_formater }}</div>
          <div v-else>{{ call.forward_from_name }}</div>
        </div>
        <template v-if="call.forward_to_type">
          <span>to</span>
          <div v-if="call.forward_to_type == 'Number'">{{ call.forward_to_number | number_formater }}</div>
          <div v-else>{{ call.forward_to_name }}</div>
        </template>
      </template>
      <template v-if="call.answered_extn_type">
        answered by: {{call.answered_extn_user_name}}
        <vb-icon 
          :icon="call.answered_extn_type | extensionIcon"
          :class="call.answered_extn_type | extensionIcon" 
          v-b-popover.hover="call.answered_extn_name" 
          width="25px" 
          height="25px" 
        />
      </template>
      <template v-if="call.transfer_to_type">
        <!-- transfer -->
        <vb-icon 
          icon="callLogs-transfer-icon"
          v-b-popover.hover="'Transfer'" 
          width="16.448px" height="15.595px"
        />
        <span>to</span>
        <span v-if="call.transfer_to_type=='Number'">{{ call.transfer_to_number | number_formater }}</span>
        <span v-else>{{ call.transfer_to_name }}</span>
      </template>
    </p>
    <p 
      v-if="call.task" 
      v-b-tooltip 
      :title="`${call.task.spaces.prefix}#${call.task.id}`" 
      @click="!assignedData && condition.actions ? $emit('onClickInfo1',{task_modal: true}) : ''"
      class="extraInfoInCallLogs forTasks mt-1 mb-0 w-100"
    >
      <vb-icon 
        icon="callLogs-task-icon"
        v-b-popover.hover="'Task'" 
        width="18.1px" height="18.11px"
        class="mr-2"
      />
      <template>
        <span class="latestBlueColor">{{call.task.title}}</span>
      </template>
      <span v-if="call.task.due_date">Due: {{call.task.due_date}}</span>
      <template>
        <div 
          v-if="call.task.status" 
          class="categoryText mr-16px ml-4" 
          :style="`background-color: ${call.task.status.color};`"
        >
          {{call.task.status.title}}
        </div>
        <div v-if="call.task.is_important==1" class="categoryText mx-2 px-1" :style="`background-color: #F2F2F2;`">
          <b-icon icon="exclamation-lg" variant="danger" />
        </div>
      </template>
      <template v-if="call.task.assigned.length!=0">
        <vb-icon icon="callLogs-task-assignedTo-icon" width="11.851px" height="14.227px" />
        Assigned to <Info :id="call.task.assigned[0].assigned_to" :only_name="true" />
      </template>
    </p>
  </div>
</template>

<script>
import { events, VOIP_API } from "@/utils";
import _ from "lodash";
import { mapGetters } from 'vuex';
import { number_formater } from '@/filter';
import Info from '@/components/Lists/Info.vue';
import { GET_LOCAL_SETTING_DASHBOARD } from '@/store/helper/getters';
export default {
  components: { Info },
  name: "CallActivityItem1",
  props: {
    call: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    assignedData: {
      type: Object,
    },
    tags_note_show: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'activity',
    },
    conditions: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      todo_added: false,
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getVoipCallTags',
      'getCurrentUser',
      'getIsMobile',
      GET_LOCAL_SETTING_DASHBOARD
    ]),
    note() {
      if (!this.call.tags_notes) return "";
      const [note] = this.call.tags_notes.filter((item) => item.log !== null && item.type === "note");
      return note ? note.log : "";
    },
    tags() {
      let allTags = this.call.tags_notes?.filter?.((item) => item.type=="tag" && !!item.tag) ?? []
      let filteredTags = []
      allTags.forEach(item => {
        if(item.tag?.deletedtags<1){
          filteredTags.push(item)
        }
      });
      return filteredTags
    },
    tagsId() {
      if (!this.call.tags_notes) return [];
      return this.call.tags_notes.filter((item) => item.type=="tag" && !!item.tag).map(tag=>tag.tag.id);
    },
    callID() {
      return this.call?.uniqueid;
    },
    condition(){
      return {
        tag: _.isUndefined(_.get(this.conditions,'tag')) ? true : _.get(this.conditions,'tag'),
        note: _.isUndefined(_.get(this.conditions,'note')) ? true : _.get(this.conditions,'note'),
        recording: _.isUndefined(_.get(this.conditions,'recording')) ? true : _.get(this.conditions,'recording'),
        message: _.isUndefined(_.get(this.conditions,'message')) ? true : _.get(this.conditions,'message'),
        assign: _.isUndefined(_.get(this.conditions,'assign')) ? false : _.get(this.conditions,'assign'),
        actions: _.isUndefined(_.get(this.conditions,'actions')) ? true : _.get(this.conditions,'actions'),
      }
    },
    isTodoAdded(){ return this.todo_added },
  },
  filters: {
    extensionIcon(value){
      if(value=='WEBEXT') return 'devices-webExtension-icon'
      else if(value=='DESKTOPEXT') return 'devices-desktopExtension-icon'
      else if(value=='MOBILEEXT') return 'devices-mobileExtension-icon'
      else if(value=='HEADEXT') return 'devices-headExtension-icon'
      else if(value=='INTEGRATIONEXT') return 'devices-headExtension-icon'
      else if(value=='EXTERNALEXT') return 'devices-headExtension-icon'
      else return 'devices-webExtension-icon'
    },
  },
  methods: {
    async addTodo(){
      if(this.api.add_todo.send || this.isTodoAdded) return;
      try {
        this.api.add_todo.send=true
        await VOIP_API.endpoints.todos.addTodo({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          cdr_id: this.callID,
        })
        this.todo_added=true
        this.appNotify({
          message: 'Successfully Added to Tasks',
          type: 'success',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.add_todo.send=false
      }
    },
    async addTodoTask(){
      if(this.api.add_todo.send || this.isTodoAdded) return;
      try {
        this.api.add_todo.send=true
        let type = 'outgoing'
        if(this.call.status=='Missed') type='missed'
        else if(this.call.status=='Received') type='incoming'
        const { data } = await VOIP_API.endpoints.tasklist.callAssign({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          title: number_formater(this.callInfo.user_name),
          cdr_id: this.callID,
          type: type
        })
        this.todo_added=true
        this.appNotify({
          message: 'Successfully Added to Tasks',
          type: 'success',
        })
        this.$modal.show('GlobalEditTask',{
          id: data.id,
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.add_todo.send=false
      }
    },
    calling(calling_type) {
      const { call_type } = this.call;
      const { dialable } = this.callInfo
      if (call_type=='video') {
        const accounts = typeof dialable === "string" ? [dialable] : dialable;
        this.$root.$emit(events.video_call, { 
          accounts, 
          call_type: calling_type ?? 'audio' 
        });
      } else {
        const number = number_formater(dialable)
        this.$root.$emit(events.audio_call, { 
          number: number 
        });
      }
    },
    onClickInfo() {
      this.$emit("onClickInfo");
    },
    sms(call) {
      this.$root.$emit(events.send_sms, { dialable: call.dialable });
    },
  },
};
</script>

<style>
</style>