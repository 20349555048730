<template>
  <div ref="addressBookContainer">
    <div v-if="openedFromMeetings">
      <div v-if="conditions.screen==screens.add">
        <b-form @submit.prevent="createAddressbook()">
          <div class="dialer_activity_box_header">
            <div class="d-flex align-items-center">
              <h4 class="w-100 text-center">Add {{ selected.tab==tabs.local ? 'my' : 'organization' }} contact</h4>
            </div>
          </div>
          <div class="d-flex mt-20px">
            <b-form-group class="whiteBGinputWithGreyRoundedBorder mb-0 w-50 pr-2" label="First name *">
              <b-form-input 
                type="text" 
                v-model="forms.create_addressbook.firstname" 
                :disabled="api.create_addressbook.send"
                :maxlength="$v.forms.create_addressbook.firstname.$params.maxLength.max" 
                placeholder="First name" 
              />
              <p 
                v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.firstname.$invalid) || api.create_addressbook.validation_errors.firstname" 
                class="text text-danger animated bounceIntop"
              >
                <span v-if="!$v.forms.create_addressbook.firstname.required">*First name is required</span>
                <span v-if="!$v.forms.create_addressbook.firstname.maxLength">
                *First name should be more than {{$v.forms.create_addressbook.firstname.$params.maxLength.max}} characters
              </span>
                <span v-for="(em,i) in api.create_addressbook.validation_errors.firstname" :key="i">{{ em }}</span>
              </p>
            </b-form-group>
            <b-form-group label="Last Name" class="whiteBGinputWithGreyRoundedBorder mb-0 w-50 pl-2">
              <b-form-input 
                type="text" 
                v-model="forms.create_addressbook.lastname" 
                :disabled="api.create_addressbook.send"
                placeholder="Last name" 
                :maxlength="$v.forms.create_addressbook.lastname.$params.maxLength.max" 
              />
              <p 
                v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.lastname.$invalid) || api.create_addressbook.validation_errors.lastname" 
                class="text text-danger animated bounceIntop"
              >
                <span v-if="!$v.forms.create_addressbook.lastname.maxLength">
                  *Last name should be more than {{$v.forms.create_addressbook.lastname.$params.maxLength.max}} characters
                </span>
                <span v-for="(em,i) in api.create_addressbook.validation_errors.lastname" :key="i">{{ em }}</span>
              </p>
            </b-form-group>
          </div>
          <div class="d-flex mt-20px align-items-end">
            <b-form-group label=" Company" class="whiteBGinputWithGreyRoundedBorder mb-0 w-50 pr-2">
              <b-form-input 
                type="text" 
                v-model="forms.create_addressbook.company" 
                placeholder="Company" 
                :disabled="api.create_addressbook.send"
              />
              <p 
                v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.company.$invalid) || api.create_addressbook.validation_errors.company" 
                class="text text-danger animated bounceIntop"
              >
                <span v-if="!$v.forms.create_addressbook.company.minLength">
                  *Company should be more than {{$v.forms.create_addressbook.company.$params.minLength.min}} characters
                </span>
                <span v-for="(em,i) in api.create_addressbook.validation_errors.company" :key="i">{{ em }}</span>
              </p>
            </b-form-group>
            <b-form-group class="w-50 mb-0">
              <div class="d-flex justify-content-end w-100">
                <a 
                  v-if="forms.create_addressbook.phones.length<=10"
                  class="newButton" 
                  @click="api.create_addressbook.send ? '' : forms.create_addressbook.phones.unshift({ phone: 'Main', number: '', shortcode: '', country: 'GB' })"
                >
                  <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                  <span class="newButton-textPart">
                    <span>Add phone</span>
                  </span>
                </a>
              </div>
            </b-form-group>
          </div>
          <p 
            v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.phones.$invalid) || api.create_addressbook.validation_errors.phones" 
            class="text text-danger animated bounceIntop mt-8px"
          >
            <span v-if="!$v.forms.create_addressbook.phones.required">*phones is required</span>
            <span v-for="(em,i) in api.create_addressbook.validation_errors.phones" :key="i">{{ em }}</span>
          </p>
          <div class="add_fields add_phone_box">
            <b-form-group class="mb-0 mt-20px" v-for="(row, index) in forms.create_addressbook.phones" :key="index">
              <div class="d-flex align-items-end">
                <div class="d-flex flex-fill">
                  <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-25 pr-2 mb-0">
                    <legend class="form-control-label small">Shortcode</legend>
                    <select v-model="row.shortcode" :disabled="api.create_addressbook.send" class="form-control custom-select">
                      <option disabled value="">Select</option>
                      <option value="">not select</option>
                      <option 
                        v-for="shortcode in (row.shortcode ? [row.shortcode,...filterShortCodes] : filterShortCodes)" 
                        :value="shortcode" 
                        :key="shortcode"
                      >
                        {{ shortcode }}
                      </option>
                    </select>
                    <p 
                      v-if="forms.create_addressbook.submitted && $v.forms.create_addressbook.phones.$each[index].shortcode.$invalid" 
                      class="text text-danger animated bounceIntop"
                    >
                      <!-- <span v-if="!$v.forms.create_addressbook.phones.$each[index].shortcode.required">* Shortcode is required</span> -->
                    </p>
                  </div>
                  <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-25 px-2 mb-0">
                    <legend class="form-control-label small">Type</legend>
                    <select v-model="row.phone" :disabled="api.create_addressbook.send" class="form-control custom-select" placeholder="Phone">
                      <option value="" disabled>Select</option>
                      <option value="Home">Home</option>
                      <option value="Office">Office</option>
                      <option value="Cell">Cell</option>
                      <option value="Work">Work</option>
                      <option value="Main">Main</option>
                      <option value="HOME FAX">Home Fax</option>
                      <option value="Office FAX">Office Fax</option>
                      <option value="Others">Others</option>
                    </select>
                    <p 
                      v-if="forms.create_addressbook.submitted && $v.forms.create_addressbook.phones.$each[index].phone.$invalid" 
                      class="text text-danger animated bounceIntop"
                    >
                      <span v-if="!$v.forms.create_addressbook.phones.$each[index].phone.required">* Type is required</span>
                    </p>
                  </div>
                  <div class="whiteBGinputWithGreyRoundedBorder w-50 px-2 mb-0">
                    <legend>Number</legend>
                    <div class="whiteBGinputWithGreyRoundedBorder joinWithInput ">
                      <b-dropdown :disabled="api.create_addressbook.send">
                        <template #button-content>
                          {{ row.country }}
                        </template>
                        <b-dropdown-item v-for="country in countries" :key="country.value" @click="row.country=country.value">{{ country.text }}</b-dropdown-item>
                      </b-dropdown>
                      <b-form-input
                        type="text"
                        :disabled="api.create_addressbook.send"
                        v-vb-input-number-formate="row.country"
                        @input="onInputNumberField(index)"
                        v-model="row.number"
                        placeholder="Number"
                      />
                    </div>
                    <p 
                      v-if="forms.create_addressbook.submitted && $v.forms.create_addressbook.phones.$each[index].number.$invalid" 
                      class="text text-danger animated bounceIntop"
                    >
                      <span v-if="!$v.forms.create_addressbook.phones.$each[index].number.required">* Number is required</span>
                      <span v-else-if="!$v.forms.create_addressbook.phones.$each[index].number.validNumber">* Number is invalid</span>
                    </p>
                  </div>
                </div>
                <span v-if="forms.create_addressbook.phones.length > 1" class="delete_box mb-2 ml-2">
                  <a @click="api.create_addressbook.send ? '' : forms.create_addressbook.phones.splice(index, 1)" class="cursor close_area">
                    <b-icon icon="trash" scale="1.3" variant="danger"></b-icon>
                  </a>
                </span>
              </div>
            </b-form-group>
          </div>
          <template v-if="forms.create_addressbook.show_more">
            <div class="d-flex mt-20px">
              <b-form-group label="job title" class="whiteBGinputWithGreyRoundedBorder mb-0 w-50 pr-2">
                <b-form-input 
                  type="text" 
                  :disabled="api.create_addressbook.send"
                  v-model="forms.create_addressbook.jobtitle" 
                  placeholder="Job title" 
                />
                <p 
                  v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.jobtitle.$invalid) || api.create_addressbook.validation_errors.jobtitle" 
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.create_addressbook.jobtitle.minLength" >
                    Job title should be more than {{$v.forms.create_addressbook.jobtitle.$params.minLength.min}} characters
                  </span>
                  <span v-for="(em,i) in api.create_addressbook.validation_errors.jobtitle" :key="i">{{ em }}</span>
                </p>
              </b-form-group>
              <b-form-group label=" Department" class="whiteBGinputWithGreyRoundedBorder mb-0 w-25 px-2">
                <b-form-input 
                  type="text" 
                  :disabled="api.create_addressbook.send"
                  v-model="forms.create_addressbook.department" 
                  placeholder="Department" 
                />
                <p 
                  v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.department.$invalid) || api.create_addressbook.validation_errors.department" 
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.create_addressbook.department.minLength">
                    *Department should be more than {{$v.forms.create_addressbook.department.$params.minLength.min}} characters
                  </span>
                  <span v-for="(em,i) in api.create_addressbook.validation_errors.department" :key="i">{{ em }}</span>
                </p>
              </b-form-group>
              <b-form-group label="Suffix" class="whiteBGinputWithGreyRoundedBorder dropdownVersion mb-0 w-25 pl-2">
                <vb-select 
                  v-model="forms.create_addressbook.suffix" 
                  :disabled="api.create_addressbook.send"
                  class="right-0 selectVoice-dropdown" 
                  :defaultSelectedText="'Select suffix'" 
                  :options="suffixs" 
                />
                <p 
                  v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.suffix.$invalid) || api.create_addressbook.validation_errors.suffix" 
                  class="text text-danger animated bounceIntop"
                >
                  <span v-for="(em,i) in api.create_addressbook.validation_errors.suffix" :key="i">{{ em }}</span>
                </p>
              </b-form-group>
            </div>
            <div class="add_fields add_email_box">
              <div class="d-flex flex-column">
                <b-form-group class="mb-0">
                  <div class="d-flex justify-content-end w-100 mt-20px">
                    <a 
                      v-if="forms.create_addressbook.emails.length<=10"
                      class="newButton" 
                      @click="api.create_addressbook.send ? '' : forms.create_addressbook.emails.unshift({ emails: '', })"
                    >
                      <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                      <span class="newButton-textPart">Add email</span>
                    </a>
                  </div>
                </b-form-group>
              </div>
              <div class v-for="(row, index) in forms.create_addressbook.emails" :key="index">
                <b-form-group class="mt-20px mb-0">
                  <div class="d-flex align-items-center">
                    <div class="whiteBGinputWithGreyRoundedBorder flex-fill">
                      <input 
                        type="email" 
                        v-model="row.emails" 
                        :disabled="api.create_addressbook.send" 
                        class="d-inline-flex flex-grow-1 adjust-width add_new_email_row form-control" 
                        placeholder="Email" 
                      />
                      <p 
                        v-if="forms.create_addressbook.submitted && $v.forms.create_addressbook.emails.$each[index].emails.$invalid" 
                        class="text text-danger animated bounceIntop"
                      >
                        <span v-if="!$v.forms.create_addressbook.emails.$each[index].emails.email">* email is not valid</span>
                      </p>
                    </div>
                    <span class="delete_box m-2">
                      <a @click="api.create_addressbook.send ? '' : forms.create_addressbook.emails.splice(index, 1)" class="cursor close_area">
                        <b-icon icon="trash" font-scale="1.8" variant="danger" />
                      </a>
                    </span>
                  </div>
                </b-form-group>
              </div>
            </div>
            <b-form-group class="whiteBGinputWithGreyRoundedBorder mb-0 mt-20px">
              <b-form-input type="text" v-model="forms.create_addressbook.note" :disabled="api.create_addressbook.send" placeholder="Note" />
              <p 
                v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.note.$invalid) || api.create_addressbook.validation_errors.note" 
                class="text text-danger animated bounceIntop"
              >
                <span v-for="(em,i) in api.create_addressbook.validation_errors.note" :key="i">{{ em }}</span>
              </p>
            </b-form-group>
          </template>
          <div class="d-flex align-items-center justify-content-center mt-20px">
            <a @click="api.create_addressbook.send ? '' : forms.create_addressbook.show_more = !forms.create_addressbook.show_more" class="dialer-link-more">
              {{ forms.create_addressbook.show_more ? "Hide" : "Show more . . ." }}
            </a>
          </div>
          <div class="d-flex justify-content-end mt-20px">
            <button class="newDoneButton" :disabled="api.create_addressbook.send" type="submit">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                <vb-spinner v-if="api.create_addressbook.send" />
                <template v-else>Save</template>
              </span>
            </button>
          </div>
        </b-form>
      </div> 
      <div v-else-if="conditions.screen==screens.update">
        <b-form @submit.prevent="updateAddressbook()" class="dialer_form">
          <div class="dialer_activity_box_header">
            <div class="d-flex align-items-center justify-content-between">
              <a class="goToPreviousScreen" @click="api.update_addressbook.send ? '' : conditions.screen=screens.list">
                <vb-svg 
                  name="leftLongArrow-icon" 
                  width="20" 
                  height="12" 
                  viewBox="0 0 30 16" 
                  stroke-width="1" 
                  stroke="" 
                  fill="none" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                />
              </a>
              <h4 class="w-100 text-center">Edit {{ selected.tab }} contact</h4>
            </div>
          </div>
          <div 
            class="profile-image-section-container" 
            @click="$modal.show('AddressBookProfileImageUploader',{ 
              type: 'ADDRESS_BOOK', 
              accountcode: forms.update_addressbook.id, 
              current_image: getProperty(editAddressBook,'profile_image') 
            })"
          > 
            <div class="profile-image-section">
              <div class="profile-img">
                <img style="height: 100%; width: 100%" :src="editAddressBook | get_property('profile_image')" @error="$event.target.src=local_filess.images.user_male"  />
              </div>
            </div>
          </div>
          <b-form-group label="First name" class="mb-0 mt-20px whiteBGinputWithGreyRoundedBorder">
            <b-form-input 
              type="text" 
              v-model="forms.update_addressbook.firstname" 
              :disabled="api.update_addressbook.send"
              :maxlength="$v.forms.update_addressbook.firstname.$params.maxLength.max" 
              placeholder="First name" 
            />
            <p 
              v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.firstname.$invalid) || api.update_addressbook.validation_errors.firstname" 
              class="text text-danger animated bounceIntop"
            >
              <span v-if="!$v.forms.update_addressbook.firstname.required">*First name is required</span>
              <span v-if="!$v.forms.update_addressbook.firstname.maxLength">
                First name should be more than {{$v.forms.update_addressbook.firstname.$params.maxLength.max}} characters
              </span>
              <span v-for="(em,i) in api.update_addressbook.validation_errors.firstname" :key="i">{{ em }}</span>
            </p>
          </b-form-group>
          <b-form-group label="Last name" class="mb-0 mt-20px whiteBGinputWithGreyRoundedBorder">
            <b-form-input 
              type="text" 
              v-model="forms.update_addressbook.lastname" 
              :disabled="api.update_addressbook.send"
              :maxlength="$v.forms.update_addressbook.lastname.$params.maxLength.max" 
              placeholder="Last name" 
            />
            <p 
              v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.lastname.$invalid) || api.update_addressbook.validation_errors.lastname" 
              class="text text-danger animated bounceIntop"
            >
              <span v-if="!$v.forms.update_addressbook.lastname.maxLength">
                Last name should be more than {{$v.forms.update_addressbook.lastname.$params.maxLength.max}} characters
              </span>
              <span v-for="(em,i) in api.update_addressbook.validation_errors.lastname" :key="i">{{ em }}</span>
            </p>
          </b-form-group>
          <b-form-group label=" Company" class="mb-0 mt-20px whiteBGinputWithGreyRoundedBorder">
            <b-form-input 
              type="text" 
              v-model="forms.update_addressbook.company" 
              :disabled="api.update_addressbook.send"
              placeholder="Company" 
            />
            <p 
              v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.company.$invalid) || api.update_addressbook.validation_errors.company" 
              class="text text-danger animated bounceIntop"
            >
              <span v-if="!$v.forms.update_addressbook.company.minLength">
                *Company should be more than {{$v.forms.update_addressbook.company.$params.minLength.min}} characters
              </span>
              <span v-for="(em,i) in api.update_addressbook.validation_errors.company" :key="i">{{ em }}</span>
            </p>
          </b-form-group>
          <div class="add_fields add_phone_box">
            <div class="d-flex flex-column">
              <b-form-group class="mb-0">
                <div class="d-flex justify-content-end w-100 mt-20px">
                  <div 
                    v-if="forms.update_addressbook.phones.length<=10"
                    class="dialer-button-primary dialer-button" 
                    @click="api.update_addressbook.send ? '' : forms.update_addressbook.phones.unshift({ phone: 'Main', number: '', shortcode: '', country: 'GB' })"
                  >
                    <b-icon icon="plus-circle-fill" class="mr-3" variant="white"/> 
                    <span>Add phone</span>
                  </div>
                </div>
              </b-form-group>
              <b-form-group class="mb-0 mt-20px" v-for="(row, index) in forms.update_addressbook.phones" :key="index">
                <div>
                  <div class="d-flex w-100">
                    <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pr-2 mb-0">
                      <select v-model="row.shortcode" :disabled="api.update_addressbook.send" class="form-control custom-select">
                        <option disabled value>code</option>
                        <option value="">not select</option>
                        <option 
                          v-for="shortcode in (row.shortcode ? [row.shortcode,...filterShortCodes] : filterShortCodes)" 
                          :value="shortcode" 
                          :key="shortcode"
                        >
                          {{ shortcode }}
                        </option>
                      </select>
                      <p 
                        v-if="forms.update_addressbook.submitted && $v.forms.update_addressbook.phones.$each[index].shortcode.$invalid" 
                        class="text text-danger animated bounceIntop"
                      >
                        <!-- <span v-if="!$v.forms.update_addressbook.phones.$each[index].shortcode.required">* shortcode is required</span> -->
                      </p>
                    </div>
                    <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pl-2 mb-0">
                      <select v-model="row.phone" :disabled="api.update_addressbook.send" class="form-control custom-select" placeholder="Phone">
                        <option value="Home">Home</option>
                        <option value="Office">Office</option>
                        <option value="Cell">Cell</option>
                        <option value="Work">Work</option>
                        <option value="Main">Main</option>
                        <option value="HOME FAX">HOME FAX</option>
                        <option value="Office FAX">Office FAX</option>
                        <option value="Others">Others</option>
                      </select>
                      <p 
                        v-if="forms.update_addressbook.submitted && $v.forms.update_addressbook.phones.$each[index].phone.$invalid" 
                        class="text text-danger animated bounceIntop"
                      >
                        <span v-if="!$v.forms.update_addressbook.phones.$each[index].phone.required">* type is required</span>
                      </p>
                    </div>
                  </div>
                  <div class="whiteBGinputWithGreyRoundedBorder mt-20px">
                    <b-dropdown :disabled="api.update_addressbook.send">
                      <template #button-content>
                        {{ row.country }}
                      </template>
                      <b-dropdown-item v-for="country in countries" :key="country.value" @click="row.country=country.value">{{ country.text }}</b-dropdown-item>
                    </b-dropdown>
                    <b-form-input :disabled="api.update_addressbook.send" v-vb-input-number-formate="row.country" @input="onInputNumberField(index)" type="text" v-model="row.number" placeholder="Number" />
                    <p 
                      v-if="forms.update_addressbook.submitted && $v.forms.update_addressbook.phones.$each[index].number.$invalid" 
                      class="text text-danger animated bounceIntop"
                    >
                      <span v-if="!$v.forms.update_addressbook.phones.$each[index].number.required">* number is required</span>
                      <span v-else-if="!$v.forms.update_addressbook.phones.$each[index].number.validNumber">* Number is invalid</span>
                    </p>
                  </div>
                </div>
                <div class="d-flex justify-content-end mt-20px">
                  <span v-if="forms.update_addressbook.phones.length > 1" class="delete_box">
                    <a @click="api.update_addressbook.send ? '' : forms.update_addressbook.phones.splice(index, 1)" class="cursor close_area">
                      <b-icon icon="trash" scale="1.3" variant="danger"></b-icon>
                    </a>
                  </span>
                </div>
              </b-form-group>
              <p 
                v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.phones.$invalid) || api.update_addressbook.validation_errors.phones" 
                class="text text-danger animated bounceIntop"
              >
                <span v-if="!$v.forms.update_addressbook.phones.required">*phones is required</span>
                <span v-if="!$v.forms.update_addressbook.phones.minLength">*phones minimum {{$v.forms.update_addressbook.phones.$params.minLength.min}} is required</span>
              <span v-for="(em,i) in api.update_addressbook.validation_errors.phones" :key="i">{{ em }}</span>
              </p>
            </div>
          </div>
          <template v-if="forms.update_addressbook.show_more">
            <b-form-group label="job title" class="mb-0 mt-20px whiteBGinputWithGreyRoundedBorder">
              <b-form-input type="text" v-model="forms.update_addressbook.jobtitle" :disabled="api.update_addressbook.send" placeholder="Job title" />
              <p 
                v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.jobtitle.$invalid) || api.update_addressbook.validation_errors.jobtitle" 
                class="text text-danger animated bounceIntop"
              >
                <span v-if="!$v.forms.update_addressbook.jobtitle.minLength">
                  Job title should be more than {{$v.forms.update_addressbook.jobtitle.$params.minLength.min}} characters
                </span>
              <span v-for="(em,i) in api.update_addressbook.validation_errors.jobtitle" :key="i">{{ em }}</span>
              </p>
            </b-form-group>
            <b-form-group label=" Department" class="mb-0 mt-20px whiteBGinputWithGreyRoundedBorder">
              <b-form-input type="text" v-model="forms.update_addressbook.department" :disabled="api.update_addressbook.send" placeholder="Department" />
              <p 
                v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.department.$invalid) || api.update_addressbook.validation_errors.department" 
                class="text text-danger animated bounceIntop"
                  >
                <span v-if="!$v.forms.update_addressbook.department.minLength">
                  *Department should be more than {{$v.forms.update_addressbook.department.$params.minLength.min}} characters
                </span>
              <span v-for="(em,i) in api.update_addressbook.validation_errors.department" :key="i">{{ em }}</span>
              </p>
            </b-form-group>
            <b-form-group label="" class="mb-0 mt-20px whiteBGinputWithGreyRoundedBorder dropdownVersion">
              <vb-select 
                v-model="forms.update_addressbook.suffix" 
                :disabled="api.update_addressbook.send" 
                class="right-0 selectVoice-dropdown" 
                :defaultSelectedText="'Select suffix'" 
                :options="suffixs" 
              />
              <p 
                v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.suffix.$invalid) || api.update_addressbook.validation_errors.suffix" 
                class="text text-danger animated bounceIntop"
              >
                <span v-if="!$v.forms.update_addressbook.suffix.minLength">
                  *suffix should be more than {{$v.forms.update_addressbook.suffix.$params.minLength.min}} characters
                </span>
              <span v-for="(em,i) in api.update_addressbook.validation_errors.suffix" :key="i">{{ em }}</span>
              </p>
            </b-form-group>
            <div class="add_fields add_email_box">
              <div class="d-flex flex-column">
                <b-form-group class="mb-0">
                  <div class="d-flex justify-content-end w-100 mt-20px">
                    <a 
                      v-if="forms.update_addressbook.emails.length<=10"
                      class="dialer-button-primary dialer-button" 
                      @click="api.update_addressbook.send ? '' : forms.update_addressbook.emails.unshift({ emails: '' })"
                    >
                      <b-icon icon="plus-circle-fill" class="mr-3" variant="white"/> 
                      <span>Add email</span>
                    </a>
                  </div>
                </b-form-group>
              </div>
              <div class v-for="(row, index) in forms.update_addressbook.emails" :key="index">
                <b-form-group class="mt-20px mb-0">
                  <div class="d-flex align-items-center">
                    <div class="whiteBGinputWithGreyRoundedBorder flex-fill">
                      <input 
                        type="email" 
                        v-model="row.emails" 
                        :disabled="api.update_addressbook.send" 
                        class="d-inline-flex flex-grow-1 adjust-width add_new_email_row form-control" 
                        placeholder="Email" 
                      />
                      <p 
                        v-if="forms.update_addressbook.submitted && $v.forms.update_addressbook.emails.$each[index].emails.$invalid" 
                        class="text text-danger animated bounceIntop"
                      >
                        <span v-if="!$v.forms.update_addressbook.emails.$each[index].emails.email">* email is not valid</span>
                      </p>
                    </div>
                    <span class="delete_box m-2">
                      <a @click="api.update_addressbook.send ? '' : forms.update_addressbook.emails.splice(index, 1)" class="cursor close_area">
                        <b-icon icon="trash" font-scale="1.8" variant="danger" />
                      </a>
                    </span>
                  </div>
                </b-form-group>
              </div>
              <p 
                v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.emails.$invalid) || api.update_addressbook.validation_errors.emails" 
                class="text text-danger animated bounceIntop"
              >
                <span v-for="(em,i) in api.update_addressbook.validation_errors.emails" :key="i">{{ em }}</span>
              </p>
            </div>
            <b-form-group class="mb-0 mt-20px whiteBGinputWithGreyRoundedBorder">
              <b-form-input type="text" v-model="forms.update_addressbook.note" :disabled="api.update_addressbook.send" placeholder="Note" />
              <p 
                v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.note.$invalid) || api.update_addressbook.validation_errors.note" 
                class="text text-danger animated bounceIntop"
              >
                <span v-for="(em,i) in api.update_addressbook.validation_errors.note" :key="i">{{ em }}</span>
              </p>
            </b-form-group>
          </template>
          <div class="d-flex align-items-center justify-content-center md-mar-bottom md-mar-top">
            <a @click="api.update_addressbook.send ? '' : forms.update_addressbook.show_more = !forms.update_addressbook.show_more" class="dialer-link-more">
              {{ forms.update_addressbook.show_more ? "Show more . . ." : "Hide" }}
            </a>
          </div>
          <div class="d-flex justify-content-end">
            <button class="dialer-button dialer-button-primary" :disabled="api.update_addressbook.send" type="submit">
              <vb-spinner v-if="api.update_addressbook.send" />
              <template v-else>Update</template>
            </button>
          </div>
        </b-form>
      </div>
    </div>
    <div v-else-if="conditions.screen==screens.add" class="dialer_contact_list allow_scroll add">
      <b-form @submit.prevent="createAddressbook()">
        <div class="dialer_activity_box_header">
          <div class="d-flex align-items-center">
            <a
              class="goToPreviousScreen" 
              @click="api.create_addressbook.send ? '' : (conditions.screen=screens.list)"
            >
              <vb-svg 
                name="leftLongArrow-icon" 
                width="20" 
                height="12" 
                viewBox="0 0 30 16" 
                stroke-width="1" 
                stroke="" 
                fill="none" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
              />
            </a>
            <h4 class="w-100 text-center">Add {{ selected.tab==tabs.local ? 'my' : 'organization' }} contact</h4>
          </div>
        </div>
        <b-form-group class="whiteBGinputWithGreyRoundedBorder mb-0" label="First name *">
          <b-form-input 
            type="text" 
            oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
            v-model="forms.create_addressbook.firstname" 
            :disabled="api.create_addressbook.send"
            :maxlength="$v.forms.create_addressbook.firstname.$params.maxLength.max" 
            placeholder="First name" 
          />
          <p 
            v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.firstname.$invalid) || api.create_addressbook.validation_errors.firstname" 
            class="text text-danger animated bounceIntop"
          >
            <span v-if="!$v.forms.create_addressbook.firstname.required">*First name is required</span>
            <span v-if="!$v.forms.create_addressbook.firstname.valid">*First name is invalid</span>
            <span v-if="!$v.forms.create_addressbook.firstname.maxLength">
            *First name should be more than {{$v.forms.create_addressbook.firstname.$params.maxLength.max}} characters
          </span>
            <span v-for="(em,i) in api.create_addressbook.validation_errors.firstname" :key="i">{{ em }}</span>
          </p>
        </b-form-group>
        <b-form-group label="Last name" class="whiteBGinputWithGreyRoundedBorder mb-0 mt-20px">
          <b-form-input 
            type="text" 
            oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
            v-model="forms.create_addressbook.lastname" 
            :disabled="api.create_addressbook.send"
            placeholder="Last name" 
            :maxlength="$v.forms.create_addressbook.lastname.$params.maxLength.max" 
          />
          <p 
            v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.lastname.$invalid) || api.create_addressbook.validation_errors.lastname" 
            class="text text-danger animated bounceIntop"
          >
            <span v-if="!$v.forms.create_addressbook.lastname.maxLength">
              *Last name should be more than {{$v.forms.create_addressbook.lastname.$params.maxLength.max}} characters
            </span>
            <span v-if="!$v.forms.create_addressbook.lastname.valid">*Last name is invalid</span>
            <span v-for="(em,i) in api.create_addressbook.validation_errors.lastname" :key="i">{{ em }}</span>
          </p>
        </b-form-group>
        <b-form-group label=" Company" class="whiteBGinputWithGreyRoundedBorder mb-0 mt-20px">
          <b-form-input 
            type="text" 
            oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
            v-model="forms.create_addressbook.company" 
            placeholder="Company" 
            :disabled="api.create_addressbook.send"
          />
          <p 
            v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.company.$invalid) || api.create_addressbook.validation_errors.company" 
            class="text text-danger animated bounceIntop"
          >
            <span v-if="!$v.forms.create_addressbook.company.minLength">
              *Company should be more than {{$v.forms.create_addressbook.company.$params.minLength.min}} characters
            </span>
            <span v-if="!$v.forms.create_addressbook.company.valid">*Company is invalid</span>
            <span v-for="(em,i) in api.create_addressbook.validation_errors.company" :key="i">{{ em }}</span>
          </p>
        </b-form-group>
        <div class="add_fields add_phone_box">
          <b-form-group class="mb-0 mt-20px" v-for="(row, index) in forms.create_addressbook.phones" :key="index">
            <div>
              <div class="d-flex w-100">
                <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pr-2 mb-0">
                  <legend class="form-control-label small">Shortcode</legend>
                  <select v-model="row.shortcode" :disabled="api.create_addressbook.send" class="form-control custom-select">
                    <option disabled value="">Select</option>
                    <option value="">not select</option>
                    <option 
                      v-for="shortcode in (row.shortcode ? [row.shortcode,...filterShortCodes] : filterShortCodes)" 
                      :value="shortcode" 
                      :key="shortcode"
                    >
                      {{ shortcode }}
                    </option>
                  </select>
                  <p 
                    v-if="forms.create_addressbook.submitted && $v.forms.create_addressbook.phones.$each[index].shortcode.$invalid" 
                    class="text text-danger animated bounceIntop"
                  >
                    <!-- <span v-if="!$v.forms.create_addressbook.phones.$each[index].shortcode.required">* Shortcode is required</span> -->
                  </p>
                </div>
                <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pl-2 mb-0">
                  <legend class="form-control-label small">Type</legend>
                  <select v-model="row.phone" :disabled="api.create_addressbook.send" class="form-control custom-select" placeholder="Phone">
                    <option value="" disabled>Select</option>
                    <option value="Home">Home</option>
                    <option value="Office">Office</option>
                    <option value="Cell">Cell</option>
                    <option value="Work">Work</option>
                    <option value="Main">Main</option>
                    <option value="HOME FAX">Home fax</option>
                    <option value="Office FAX">Office fax</option>
                    <option value="Others">Others</option>
                  </select>
                  <p 
                    v-if="forms.create_addressbook.submitted && $v.forms.create_addressbook.phones.$each[index].phone.$invalid" 
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.create_addressbook.phones.$each[index].phone.required">* Type is required</span>
                  </p>
                </div>
                <div class="d-flex align-items-end">
                  <span v-if="forms.create_addressbook.phones.length > 1" class="delete_box m-2">
                    <a @click="api.create_addressbook.send ? '' : forms.create_addressbook.phones.splice(index, 1)" class="cursor close_area">
                      <b-icon icon="trash" font-scale="1.8" variant="danger"></b-icon>
                    </a>
                  </span>
                </div>
              </div>
              <div class="whiteBGinputWithGreyRoundedBorder joinWithInput mt-20px">
                <b-dropdown :disabled="api.create_addressbook.send">
                  <template #button-content>
                    {{ row.country }}
                  </template>
                  <b-dropdown-item v-for="country in countries" :key="country.value" @click="row.country=country.value">{{country.value}} {{ country.text }}</b-dropdown-item>
                </b-dropdown>
                <b-form-input
                  type="text"
                  :disabled="api.create_addressbook.send"
                  @input="onInputNumberField(index)"
                  v-vb-input-number-formate="row.country" 
                  v-model="row.number"
                  placeholder="Number"
                />
                <p 
                  v-if="forms.create_addressbook.submitted && $v.forms.create_addressbook.phones.$each[index].number.$invalid" 
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.create_addressbook.phones.$each[index].number.required">* Number is required</span>
                  <span v-else-if="!$v.forms.create_addressbook.phones.$each[index].number.validNumber">* Number is invalid</span>
                </p>
              </div>
            </div>
            <!-- <div class="d-flex justify-content-end mt-20px">
              <span v-if="forms.create_addressbook.phones.length > 1" class="delete_box">
                <a @click="api.create_addressbook.send ? '' : forms.create_addressbook.phones.splice(index, 1)" class="cursor close_area">
                  <b-icon icon="trash" scale="1.3" variant="danger"></b-icon>
                </a>
              </span>
            </div> -->
          </b-form-group>
          <div class="d-flex flex-column">
            <b-form-group class="mb-0">
              <div class="d-flex justify-content-end w-100 mt-20px">
                <a 
                  v-if="forms.create_addressbook.phones.length<=10"
                  class="newButton" 
                  @click="api.create_addressbook.send ? '' : forms.create_addressbook.phones.unshift({ phone: 'Main', number: '', shortcode: '', country: 'GB' })"
                >
                  <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                  <span class="newButton-textPart text-white">Add phone</span>
                </a>
              </div>
            </b-form-group>
            <p 
              v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.phones.$invalid) || api.create_addressbook.validation_errors.phones" 
              class="text text-danger animated bounceIntop"
            >
              <span v-if="!$v.forms.create_addressbook.phones.required">*phones is required</span>
              <span v-for="(em,i) in api.create_addressbook.validation_errors.phones" :key="i">{{ em }}</span>
            </p>
          </div>
        </div>
        <template v-if="forms.create_addressbook.show_more">
          <b-form-group label="job title" class="whiteBGinputWithGreyRoundedBorder mb-0">
            <b-form-input 
              type="text" 
              oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
              :disabled="api.create_addressbook.send"
              v-model="forms.create_addressbook.jobtitle" 
              placeholder="Job title" 
            />
            <p 
              v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.jobtitle.$invalid) || api.create_addressbook.validation_errors.jobtitle" 
              class="text text-danger animated bounceIntop"
            >
              <span v-if="!$v.forms.create_addressbook.jobtitle.minLength" >
                Job title should be more than {{$v.forms.create_addressbook.jobtitle.$params.minLength.min}} characters
              </span>
              <span v-if="!$v.forms.create_addressbook.jobtitle.valid">Job title is invalid</span>
              <span v-for="(em,i) in api.create_addressbook.validation_errors.jobtitle" :key="i">{{ em }}</span>
            </p>
          </b-form-group>
          <b-form-group label=" Department" class="whiteBGinputWithGreyRoundedBorder mb-0 mt-20px">
            <b-form-input 
              type="text" 
              oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
              :disabled="api.create_addressbook.send"
              v-model="forms.create_addressbook.department" 
              placeholder="Department" 
            />
            <p 
              v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.department.$invalid) || api.create_addressbook.validation_errors.department" 
              class="text text-danger animated bounceIntop"
            >
              <span v-if="!$v.forms.create_addressbook.department.minLength">
                *Department should be more than {{$v.forms.create_addressbook.department.$params.minLength.min}} characters
              </span>
              <span v-if="!$v.forms.create_addressbook.department.invalid">*Department is invalid</span>
              <span v-for="(em,i) in api.create_addressbook.validation_errors.department" :key="i">{{ em }}</span>
            </p>
          </b-form-group>
          <b-form-group label="" class="whiteBGinputWithGreyRoundedBorder dropdownVersion mb-0 mt-12px">
            <vb-select 
              v-model="forms.create_addressbook.suffix" 
              :disabled="api.create_addressbook.send"
              class="right-0 selectVoice-dropdown" 
              :defaultSelectedText="'Select suffix'" 
              :options="suffixs" 
            />
            <p 
              v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.suffix.$invalid) || api.create_addressbook.validation_errors.suffix" 
              class="text text-danger animated bounceIntop"
            >
              <span v-for="(em,i) in api.create_addressbook.validation_errors.suffix" :key="i">{{ em }}</span>
            </p>
          </b-form-group>
          <div class="add_fields add_email_box">
            <div class="d-flex flex-column">
              <b-form-group class="mb-0">
                <div class="d-flex justify-content-end w-100 mt-20px">
                  <a 
                    v-if="forms.create_addressbook.emails.length<=10"
                    class="newButton" 
                    @click="api.create_addressbook.send ? '' : forms.create_addressbook.emails.unshift({ emails: '', })"
                  >
                    <!-- <b-icon icon="plus-circle-fill" class="mr-3" variant="white"/> 
                    <span>Add email</span> -->
                    <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                    <span class="newButton-textPart text-white">Add email</span>
                  </a>
                </div>
              </b-form-group>
            </div>
            <div class v-for="(row, index) in forms.create_addressbook.emails" :key="index">
              <b-form-group class="mt-20px mb-0">
                <div class="d-flex align-items-center">
                  <div class="whiteBGinputWithGreyRoundedBorder flex-fill">
                    <input 
                      type="email" 
                      v-model="row.emails" 
                      :disabled="api.create_addressbook.send" 
                      class="d-inline-flex flex-grow-1 adjust-width add_new_email_row form-control" 
                      placeholder="Email" 
                    />
                    <p 
                      v-if="forms.create_addressbook.submitted && $v.forms.create_addressbook.emails.$each[index].emails.$invalid" 
                      class="text text-danger animated bounceIntop"
                    >
                      <span v-if="!$v.forms.create_addressbook.emails.$each[index].emails.email">* email is not valid</span>
                    </p>
                  </div>
                  <span class="delete_box m-2">
                    <a @click="api.create_addressbook.send ? '' : forms.create_addressbook.emails.splice(index, 1)" class="cursor close_area">
                      <b-icon icon="trash" font-scale="1.8" variant="danger" />
                    </a>
                  </span>
                </div>
              </b-form-group>
            </div>
          </div>
          <b-form-group class="whiteBGinputWithGreyRoundedBorder mb-0 mt-20px">
            <b-form-input 
              type="text" 
              oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
              v-model="forms.create_addressbook.note" 
              :disabled="api.create_addressbook.send" 
              placeholder="Note" 
            />
            <p 
              v-if="(forms.create_addressbook.submitted && $v.forms.create_addressbook.note.$invalid) || api.create_addressbook.validation_errors.note" 
              class="text text-danger animated bounceIntop"
            >
              <span v-if="!$v.forms.create_addressbook.note.invalid">*Note is invalid</span>
              <span v-for="(em,i) in api.create_addressbook.validation_errors.note" :key="i">{{ em }}</span>
            </p>
          </b-form-group>
        </template>
        <div class="d-flex align-items-center justify-content-center mt-20px">
          <a @click="api.create_addressbook.send ? '' : forms.create_addressbook.show_more = !forms.create_addressbook.show_more" class="dialer-link-more">
            {{ forms.create_addressbook.show_more ? "Hide" : "Show more . . ." }}
          </a>
        </div>
        <div class="d-flex justify-content-end mt-20px">
          <button class="fullWidthDoneButton" :disabled="api.create_addressbook.send" type="submit">
            <vb-spinner v-if="api.create_addressbook.send" />
            <template v-else>Save</template>
          </button>
        </div>
      </b-form>
    </div>
    <div v-else-if="conditions.screen==screens.list">
      <div class="tab-pane active">
        <div class="dialer-tabs">
          <div v-if="getIsMobile" class="updatingContactsAgain-topBar-secondBar">
            <div 
              class="updatingContactsAgain-topBar-secondBar-tab" 
              :class="{ 
                active: selected.tab==tab 
              }" 
              v-for="tab in tabs" 
              :key="tab" 
              @click="selected.tab=tab"
            >
              {{ tab==tabs.local ? 'Mine' : 'Shared' }}
            </div>
          </div>
          <div v-else class="dialer-tab-headers align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <div 
                class="dialer-tab"
                :class="{ 
                  selected: selected.tab==tab 
                }" 
                v-for="tab in tabs" 
                :key="tab" 
                @click="selected.tab=tab"
              >
                {{ tab==tabs.local ? 'My' : 'Organization' }}
              </div>
            </div>
          </div>
          <div v-if="selected.tab==tabs.local" class="dialer-tab-content">
            <div v-if="getIsMobile">
              <div class="inContacts-contacts-searchBar-container">
                <b-form class="updatingContactsAgain-contacts-searchBar-container" @submit.prevent="''">
                  <div class="updatingContactsAgain-contacts-searchBar">
                    <vb-icon icon="mobile-searchBar-icon-likeAirCall-icon" width="12.68px" height="12.67px"/>
                    <b-input v-model="filter.local_addressbooks.search" placeholder="Search"/>
                  </div>
                  <b-dropdown no-caret class="filterDD noPaddingInButton">
                    <template #button-content>
                      <vb-icon icon="mobile-funnel-icon-likeAirCall-icon" width="18.61px" height="18.61px" />
                    </template>
                    <b-dropdown-item>
                      <div class="d-flex align-items-center">
                        <b-icon icon="check" class="mr-2" :style="`opacity:1;`"/>
                        <div>All</div>
                      </div>
                    </b-dropdown-item>
                    <b-dropdown-item v-for="platform in response.integrateds" :key="platform.platform">
                      <div class="d-flex align-items-center">
                        <b-icon icon="check" class="mr-2" :style="`opacity:0;`"/>
                        <div>{{ platform.platform }}</div>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                  <vb-icon 
                    @click="$store.state.global_conditions.add_addressbooks = true;" 
                    icon="mobile-plus-icon-likeAirCall-icon" 
                    style="cursor:pointer;" 
                    width="20.38px" 
                    height="20.38px"
                  />
                </b-form>
              </div>
              <div @scroll="onLocalAddressBookScroll($event)" class="dialer_contact_list addressbook_list allow_scroll">
                <div class="dialer_contact_row" v-for="addressbook in filterLocalAddressBooks" :key="addressbook.real_id">
                  <div class="updatingContactsAgain-contact-item">
                    <div class="d-flex">
                      <vb-avatar class="sm-mar-right" :image="addressbook | get_property('profile_image')" />
                      <div class="user-info">
                        <div class="user-name-box">
                          <span class="user-name">
                            <span>{{`${addressbook.firstName} ${addressbook.lastName || ''}`}}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <vb-spinner variant="primary" v-if="api.delete_addressbook.send==addressbook.real_id" />
                    <b-dropdown v-else varient="link" no-caret class="ico-filter">
                      <template v-slot:button-content>
                        <span class="speedial_code updateSpeedDialCodeAgain">{{ addressbook | get_property('main_phone.dialShortCode') }}</span>
                        <vb-icon icon="mobile-topBar-threeDots-likeAirCall-icon" class="mobile-topBar-threeDots-likeAirCall-icon" height="27.06px" width="6px" />
                      </template>
                      <b-dropdown-item @click="audioCall(addressbook)">
                        <b-icon icon="telephone-fill" />
                        <span class="ml-2">Call</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="setUpdate(addressbook)">
                        <b-icon icon="pencil-square" />
                        <span class="ml-2">Edit</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="deleteAddressBook(addressbook)">
                        <b-icon icon="trash-fill" />
                        <span class="ml-2">Delete</span>
                      </b-dropdown-item>
                      <b-dropdown-item :disabled="api.toggle_favourite.send.includes(addressbook.real_id)" @click="toggleFavourite(addressbook)">
                        <b-icon icon="star-fill"/>
                        <span class="ml-2">{{ addressbook.is_favourite ? 'Remove from favourites' : 'Add to favourites' }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <vb-loading v-if="api.fetch_local_addressbooks.send"  />
                <vb-no-record v-else-if="isEmpty(filterLocalAddressBooks)" :text="api.fetch_local_addressbooks.send ? '' : 'There is no addressbook'" :design="3" />
              </div>
            </div>
            <div v-else>
              <div class="integrationFilterWithSearchBar-container mb-3">
                <b-form class="whiteBGinputWithGreyRoundedBorder flex-fill" @submit.prevent="''">
                  <b-input v-model="filter.local_addressbooks.search" placeholder="Search here"/>
                </b-form>
                <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD">
                  <b-dropdown no-caret right>
                    <template #button-content>
                      <b-icon icon="three-dots-vertical" font-scale="1.7"/>
                    </template>
                    <b-dropdown-item>
                      <div class="d-flex align-items-center">
                        <b-icon icon="check" class="mr-2" :style="`opacity:1;`"/>
                        <div>All</div>
                      </div>
                    </b-dropdown-item>
                    <b-dropdown-item v-for="platform in response.integrateds" :key="platform.platform">
                      <div class="d-flex align-items-center">
                        <b-icon icon="check" class="mr-2" :style="`opacity:0;`"/>
                        <div>{{ platform.platform }}</div>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div class="dialer-addContact cursor_pointer" @click="$store.state.global_conditions.add_addressbooks = true;">
                <vb-svg 
                  name="dialer-addUser-icon" 
                  width="23.401" 
                  height="18.295" 
                  viewBox="0 0 23.401 18.295" 
                  stroke-width="0" 
                  stroke="" 
                  fill="none" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                />
                <span class="">New contact</span>
              </div>
              <div @scroll="onLocalAddressBookScroll($event)" class="dialer_contact_list addressbook_list allow_scroll">
                <div class="dialer_contact_row" v-for="addressbook in filterLocalAddressBooks" :key="addressbook.real_id">
                  <div class="dialer_contact">
                    <vb-avatar :image="addressbook | get_property('profile_image')" />
                    <vb-icon v-if="addressbook.tags" @click="openIntegrationContactDetail(addressbook)" :icon="addressbook.tags | integrationIcons" class="integrationType-icon" />
                    <div class="user-info">
                      <div class="user-name-box">
                        <span class="user-name">
                          <span>{{`${addressbook.firstName} ${addressbook.lastName || ''}`}}</span>
                          <span v-if="addressbook.is_favourite==1">
                            <b-icon icon="star-fill" variant="primary"/>
                          </span>
                        </span>
                        <div class="dialer_contact_hidden_area">
                          <div class="dialer_contact_connection_box">
                            <span class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute">
                              <vb-spinner variant="primary" v-if="api.delete_addressbook.send==addressbook.real_id" />
                              <b-dropdown v-else varient="link" no-caret class="ico-filter">
                                <template v-slot:button-content>
                                  <span class="speedial_code">{{ addressbook | get_property('main_phone.dialShortCode') }}</span>
                                  <b-icon icon="three-dots" scale="1.5" />
                                </template>
                                <b-dropdown-item @click="audioCall(addressbook)">
                                  <vb-svg 
                                    class="mr-2" 
                                    name="dialer-outlineCall-icon2" 
                                    width="16.501" 
                                    height="16.501" 
                                    viewBox="0 0 16.501 16.501" 
                                    stroke-width="0" 
                                    stroke="#005DFF" 
                                    fill="none" 
                                    stroke-linecap="round" 
                                    stroke-linejoin="round" 
                                  />
                                  <span>Call</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="setUpdate(addressbook)">
                                  <vb-svg 
                                    name="dialer-edit-icon" 
                                    width="16" 
                                    class="mr-2" 
                                    height="16" 
                                    viewBox="0 0 24 24" 
                                    stroke-width="1.2" 
                                    stroke="currentColor" 
                                    fill="none" 
                                    stroke-linecap="round" 
                                    stroke-linejoin="round" 
                                  />
                                  <span>Edit</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="deleteAddressBook(addressbook)">
                                  <vb-svg 
                                    class="dialer-icon dialer-delete-icon mr-2" 
                                    name="dialer-delete-icon" 
                                    width="16" 
                                    height="16" 
                                    viewBox="0 0 24 24" 
                                    stroke-width="1.2" 
                                    stroke="currentColor" 
                                    fill="none" 
                                    stroke-linecap="round" 
                                    stroke-linejoin="round" 
                                  />
                                  <span>Delete</span>
                                </b-dropdown-item>
                                <b-dropdown-item :disabled="api.toggle_favourite.send.includes(addressbook.real_id)" @click="toggleFavourite(addressbook)">
                                  <b-icon class="mr-2" :icon="addressbook.is_favourite ? 'star-fill' : 'star'"/>
                                  <span>{{ addressbook.is_favourite ? 'Remove from favourites' : 'Add to favourites' }}</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="user-number">{{ addressbook | get_property('main_phone.number') | number_formater }}</div>
                    </div>
                  </div>
                </div>
                <template v-if="api.fetch_local_addressbooks.send">
                  <div v-for="n in 3" :key="n.id" class="d-flex mt-20px align-items-center w-100">
                    <div class="latestShimmerDesign mr-3" style="height:40px;width:40px;"></div>
                    <div class="d-flex flex-column flex-fill">
                      <div class="latestShimmerDesign" style="height:18px;width:50%;"></div>
                      <div class="latestShimmerDesign mt-1" style="height:18px;width:80%;"></div>
                    </div>
                  </div>
                </template>
                <div v-else-if="isEmpty(filterLocalAddressBooks)" class="emptyScreenContainer mt-32px b-0 p-0">
                  <img width="50%" height="auto" v-if="GET_LOCAL_SETTING_IS_DARK_MODE" :src="require('@/assets/images/emptyScreenImages/darkMode/addressBook.png')"/>
                  <img width="50%" height="auto" v-else :src="require('@/assets/images/emptyScreenImages/addressBook.png')"/>
                  <div class="emptyScreenContainer-heading mt-32px">Explore address book</div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selected.tab==tabs.global" class="dialer-tab-content">
            <div v-if="getIsMobile">
              <div class="inContacts-contacts-searchBar-container">
                <b-form class="updatingContactsAgain-contacts-searchBar-container" @submit.prevent="''">
                  <div class="updatingContactsAgain-contacts-searchBar">
                    <vb-icon icon="mobile-searchBar-icon-likeAirCall-icon" width="12.68px" height="12.67px"/>
                    <b-input v-model="filter.global_addressbooks.search" placeholder="Search"/>
                  </div>
                  <vb-icon 
                    @click="$store.state.global_conditions.add_addressbooks = true;forms.create_addressbook.is_global=true;" 
                    icon="mobile-plus-icon-likeAirCall-icon" 
                    style="cursor:pointer;" 
                    width="20.38px" 
                    height="20.38px"
                  />
                </b-form>
              </div>
              <div @scroll="onGlobalAddressBookScroll($event)" class="dialer_contact_list addressbook_list allow_scroll">
                <div class="dialer_contact_row b-0" v-for="addressbook in filterGlobalAddressBooks" :key="addressbook.real_id">
                  <div class="updatingContactsAgain-contact-item">
                    <div class="d-flex">
                      <vb-avatar class="sm-mar-right" :image="addressbook | get_property('profile_image')" />
                      <div class="user-info">
                        <div class="user-name-box">
                          <span class="user-name">
                            <span>{{`${addressbook.firstName} ${addressbook.lastName || ''}`}}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <vb-spinner variant="primary" v-if="api.delete_addressbook.send==addressbook.real_id" />
                    <b-dropdown v-else varient="link" no-caret class="ico-filter">
                      <template #button-content>
                        <span class="speedial_code updateSpeedDialCodeAgain">{{ addressbook | get_property('main_phone.dialShortCode') }}</span>
                        <vb-icon icon="mobile-topBar-threeDots-likeAirCall-icon" class="mobile-topBar-threeDots-likeAirCall-icon" height="27.06px" width="6px" />
                      </template>
                      <b-dropdown-item @click="audioCall(addressbook)">
                        <vb-svg 
                          name="dialer-outlineCall-icon2" 
                          width="16.501" 
                            height="16.501" 
                            viewBox="0 0 16.501 16.501" 
                            stroke-width="0" 
                            stroke="#005DFF" 
                            fill="none" 
                            stroke-linecap="round" 
                            stroke-linejoin="round" 
                          />
                        <span class="ml-2">Call</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="setUpdate(addressbook)">
                        <vb-svg 
                          width="16" 
                          height="16" 
                          viewBox="0 0 24 24" 
                          stroke-width="1.2" 
                          stroke="currentColor" 
                          fill="none" 
                          stroke-linecap="round" 
                          stroke-linejoin="round" 
                          name="dialer-edit-icon" 
                        />
                        <span class="ml-2">Edit</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="deleteAddressBook(addressbook)">
                        <vb-svg 
                          class="dialer-icon dialer-delete-icon" 
                          width="16" 
                          height="16" 
                          viewBox="0 0 24 24" 
                          stroke-width="1.2" 
                          stroke="currentColor" 
                          fill="none" 
                          stroke-linecap="round" 
                          stroke-linejoin="round" 
                          name="dialer-delete-icon" 
                        />
                        <span class="ml-2">Delete</span>
                      </b-dropdown-item>
                      <b-dropdown-item :disabled="api.toggle_favourite.send.includes(addressbook.real_id)" @click="toggleFavourite(addressbook)">
                        <b-icon :icon="addressbook.is_favourite ? 'star-fill' : 'star'"/>
                        <span class="ml-2">{{ addressbook.is_favourite ? 'Remove from favourites' : 'Add to favourites' }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <vb-loading v-if="api.fetch_global_addressbooks.send" />
                <vb-no-record v-else-if="isEmpty(filterGlobalAddressBooks)" :text="api.fetch_global_addressbooks.send ? '' : 'There is no addressbook'" :design="3" />
              </div>
            </div>
            <div v-else>
              <b-form class="mb-3 whiteBGinputWithGreyRoundedBorder" @submit.prevent="''">
                <b-input v-model="filter.global_addressbooks.search" placeholder="Search here" class="mx-1" />
              </b-form>
              <div class="dialer-addContact cursor_pointer" @click="$store.state.global_conditions.add_addressbooks = true;forms.create_addressbook.is_global=true;">
                <vb-svg 
                  name="dialer-addUser-icon" 
                  width="23.401" 
                  height="18.295" 
                  viewBox="0 0 23.401 18.295" 
                  stroke-width="0" 
                  stroke="" 
                  fill="none" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                />
                <span class="">New contact</span>
              </div>
              <div @scroll="onGlobalAddressBookScroll($event)" class="dialer_contact_list addressbook_list allow_scroll">
                <div class="dialer_contact_row" v-for="addressbook in filterGlobalAddressBooks" :key="addressbook.real_id">
                  <div class="dialer_contact">
                    <vb-avatar :image="addressbook | get_property('profile_image')" />
                    <div class="user-info">
                      <div class="user-name-box">
                        <span class="user-name">
                          <span>{{`${addressbook.firstName} ${addressbook.lastName || ''}`}}</span>
                          <span v-if="addressbook.is_favourite==1">
                            <b-icon icon="star-fill" variant="primary"/>
                          </span>
                        </span>
                        <div class="dialer_contact_hidden_area">
                          <div class="dialer_contact_connection_box">
                            <span class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute">
                              <vb-spinner variant="primary" v-if="api.delete_addressbook.send==addressbook.real_id" />
                              <b-dropdown v-else varient="link" no-caret class="ico-filter">
                                <template #button-content>
                                  <span class="speedial_code">{{ addressbook | get_property('main_phone.dialShortCode') }}</span>
                                  <b-icon icon="three-dots" scale="1.5" />
                                </template>
                                <b-dropdown-item @click="audioCall(addressbook)">
                                  <vb-svg 
                                    class="mr-2" 
                                    name="dialer-outlineCall-icon2" 
                                    width="16.501" 
                                    height="16.501" 
                                    viewBox="0 0 16.501 16.501" 
                                    stroke-width="0" 
                                    stroke="#005DFF" 
                                    fill="none" 
                                    stroke-linecap="round" 
                                    stroke-linejoin="round" 
                                  />
                                  <span>Call</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="setUpdate(addressbook)">
                                  <vb-svg 
                                    class="mr-2" 
                                    width="16" 
                                    height="16" 
                                    viewBox="0 0 24 24" 
                                    stroke-width="1.2" 
                                    stroke="currentColor" 
                                    fill="none" 
                                    stroke-linecap="round" 
                                    stroke-linejoin="round" 
                                    name="dialer-edit-icon" 
                                  />
                                  <span>Edit</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="deleteAddressBook(addressbook)">
                                  <vb-svg 
                                    class="dialer-icon dialer-delete-icon mr-2" 
                                    width="16" 
                                    height="16" 
                                    viewBox="0 0 24 24" 
                                    stroke-width="1.2" 
                                    stroke="currentColor" 
                                    fill="none" 
                                    stroke-linecap="round" 
                                    stroke-linejoin="round" 
                                    name="dialer-delete-icon" 
                                  />
                                  <span>Delete</span>
                                </b-dropdown-item>
                                <b-dropdown-item :disabled="api.toggle_favourite.send.includes(addressbook.real_id)" @click="toggleFavourite(addressbook)">
                                  <b-icon :icon="addressbook.is_favourite ? 'star-fill' : 'star'" class="mr-2" />
                                  <span>{{ addressbook.is_favourite ? 'Remove from favourites' : 'Add to favourites' }}</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="user-number">{{ addressbook | get_property('main_phone.number') | number_formater }}</div>
                    </div>
                  </div>
                </div>
                <template v-if="api.fetch_global_addressbooks.send">
                  <div v-for="n in 3" :key="n.id" class="d-flex mt-20px align-items-center w-100">
                    <div class="latestShimmerDesign mr-3" style="height:40px;width:40px;"></div>
                    <div class="d-flex flex-column flex-fill">
                      <div class="latestShimmerDesign" style="height:18px;width:50%;"></div>
                      <div class="latestShimmerDesign mt-1" style="height:18px;width:80%;"></div>
                    </div>
                  </div>
                </template>
                <div v-else-if="isEmpty(filterGlobalAddressBooks)" class="emptyScreenContainer mt-32px b-0 p-0">
                  <img width="50%" height="auto" v-if="GET_LOCAL_SETTING_IS_DARK_MODE" :src="require('@/assets/images/emptyScreenImages/darkMode/addressBook.png')"/>
                  <img width="50%" height="auto" v-else :src="require('@/assets/images/emptyScreenImages/addressBook.png')"/>
                  <div class="emptyScreenContainer-heading mt-32px">Explore address book</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="conditions.screen==screens.update" class="dialer_contact_list allow_scroll add">
      <b-form @submit.prevent="updateAddressbook()" class="dialer_form">
        <div class="dialer_activity_box_header">
          <div class="d-flex align-items-center justify-content-between">
            <a class="goToPreviousScreen" @click="api.update_addressbook.send ? '' : conditions.screen=screens.list">
              <vb-svg 
                name="leftLongArrow-icon" 
                width="20" 
                height="12" 
                viewBox="0 0 30 16" 
                stroke-width="1" 
                stroke="" 
                fill="none" 
                stroke-linecap="round" 
                stroke-linejoin="round" 
              />
            </a>
            <h4 class="w-100 text-center">Edit {{ selected.tab==tabs.local ? 'my' : 'organization' }} contact</h4>
          </div>
        </div>
        <div 
          class="profile-image-section-container" 
          @click="$modal.show('AddressBookProfileImageUploader',{ 
            type: 'ADDRESS_BOOK', 
            accountcode: forms.update_addressbook.id, 
            current_image: getProperty(editAddressBook,'profile_image') 
          })"
        > 
          <div class="profile-image-section">
            <div class="profile-img">
              <img style="height: 100%; width: 100%" :src="editAddressBook | get_property('profile_image')" @error="$event.target.src=local_filess.images.user_male"  />
            </div>
          </div>
        </div>
        <b-form-group label="First name" class="mb-0 mt-20px whiteBGinputWithGreyRoundedBorder">
          <b-form-input 
            type="text" 
            oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
            v-model="forms.update_addressbook.firstname" 
            :disabled="api.update_addressbook.send"
            :maxlength="$v.forms.update_addressbook.firstname.$params.maxLength.max" 
            placeholder="First name" 
          />
          <p 
            v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.firstname.$invalid) || api.update_addressbook.validation_errors.firstname" 
            class="text text-danger animated bounceIntop"
          >
            <span v-if="!$v.forms.update_addressbook.firstname.required">*First name is required</span>
            <span v-if="!$v.forms.update_addressbook.firstname.valid">*First name is invalid</span>
            <span v-if="!$v.forms.update_addressbook.firstname.maxLength">
              First name should be more than {{$v.forms.update_addressbook.firstname.$params.maxLength.max}} characters
            </span>
            <span v-for="(em,i) in api.update_addressbook.validation_errors.firstname" :key="i">{{ em }}</span>
          </p>
        </b-form-group>
        <b-form-group label="Last name" class="mb-0 mt-20px whiteBGinputWithGreyRoundedBorder">
          <b-form-input 
            type="text" 
            oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
            v-model="forms.update_addressbook.lastname" 
            :disabled="api.update_addressbook.send"
            :maxlength="$v.forms.update_addressbook.lastname.$params.maxLength.max" 
            placeholder="Last name" 
          />
          <p 
            v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.lastname.$invalid) || api.update_addressbook.validation_errors.lastname" 
            class="text text-danger animated bounceIntop"
          >
            <span v-if="!$v.forms.update_addressbook.lastname.valid">Last name is invalid</span>
            <span v-if="!$v.forms.update_addressbook.lastname.maxLength">
              Last name should be more than {{$v.forms.update_addressbook.lastname.$params.maxLength.max}} characters
            </span>
            <span v-for="(em,i) in api.update_addressbook.validation_errors.lastname" :key="i">{{ em }}</span>
          </p>
        </b-form-group>
        <b-form-group label=" Company" class="mb-0 mt-20px whiteBGinputWithGreyRoundedBorder">
          <b-form-input 
            type="text" 
            oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
            v-model="forms.update_addressbook.company" 
            :disabled="api.update_addressbook.send"
            placeholder="Company" 
          />
          <p 
            v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.company.$invalid) || api.update_addressbook.validation_errors.company" 
            class="text text-danger animated bounceIntop"
          >
            <span v-if="!$v.forms.update_addressbook.company.valid">*Company is invalid</span>
            <span v-if="!$v.forms.update_addressbook.company.minLength">
              *Company should be more than {{$v.forms.update_addressbook.company.$params.minLength.min}} characters
            </span>
            <span v-for="(em,i) in api.update_addressbook.validation_errors.company" :key="i">{{ em }}</span>
          </p>
        </b-form-group>
        <div class="add_fields add_phone_box">
          <div class="d-flex flex-column">
            <b-form-group class="mb-0">
              <div class="d-flex justify-content-end w-100 mt-20px">
                <div 
                  v-if="forms.update_addressbook.phones.length<=10"
                  class="newButton" 
                  @click="api.update_addressbook.send ? '' : forms.update_addressbook.phones.unshift({ phone: 'Main', number: '', shortcode: '', country: 'GB' })"
                >
                  <!-- <b-icon icon="plus-circle-fill" class="mr-3" variant="white"/> 
                  <span>Add phone</span> -->
                  <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                  <span class="newButton-textPart text-white">Add phone</span>
                </div>
              </div>
            </b-form-group>
            <b-form-group class="mb-0 mt-20px" v-for="(row, index) in forms.update_addressbook.phones" :key="index">
              <div>
                <div class="d-flex w-100">
                  <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pr-2 mb-0">
                    <select v-model="row.shortcode" :disabled="api.update_addressbook.send" class="form-control custom-select">
                      <option disabled value>code</option>
                      <option value="">not select</option>
                      <option 
                        v-for="shortcode in (row.shortcode ? [row.shortcode,...filterShortCodes] : filterShortCodes)" 
                        :value="shortcode" 
                        :key="shortcode"
                      >
                        {{ shortcode }}
                      </option>
                    </select>
                    <p 
                      v-if="forms.update_addressbook.submitted && $v.forms.update_addressbook.phones.$each[index].shortcode.$invalid" 
                      class="text text-danger animated bounceIntop"
                    >
                      <!-- <span v-if="!$v.forms.update_addressbook.phones.$each[index].shortcode.required">* shortcode is required</span> -->
                    </p>
                  </div>
                  <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pl-2 mb-0">
                    <select v-model="row.phone" :disabled="api.update_addressbook.send" class="form-control custom-select" placeholder="Phone">
                      <option value="Home">Home</option>
                      <option value="Office">Office</option>
                      <option value="Cell">Cell</option>
                      <option value="Work">Work</option>
                      <option value="Main">Main</option>
                      <option value="HOME FAX">HOME FAX</option>
                      <option value="Office FAX">Office FAX</option>
                      <option value="Others">Others</option>
                    </select>
                    <p 
                      v-if="forms.update_addressbook.submitted && $v.forms.update_addressbook.phones.$each[index].phone.$invalid" 
                      class="text text-danger animated bounceIntop"
                    >
                      <span v-if="!$v.forms.update_addressbook.phones.$each[index].phone.required">* type is required</span>
                    </p>
                  </div>
                </div>
                <div class="whiteBGinputWithGreyRoundedBorder joinWithInput mt-20px d-flex">
                  <b-dropdown :disabled="api.update_addressbook.send">
                    <template #button-content>
                      {{ row.country }}
                    </template>
                    <b-dropdown-item v-for="country in countries" :key="country.value" @click="row.country=country.value">{{ country.text }}</b-dropdown-item>
                  </b-dropdown>
                  <b-form-input :disabled="api.update_addressbook.send" v-vb-input-number-formate="row.country" @input="onInputNumberField(index)" type="text" v-model="row.number" placeholder="Number" />
                  <p 
                    v-if="forms.update_addressbook.submitted && $v.forms.update_addressbook.phones.$each[index].number.$invalid" 
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.update_addressbook.phones.$each[index].number.required">* number is required</span>
                    <span v-else-if="!$v.forms.update_addressbook.phones.$each[index].number.validNumber">* Number is invalid</span>
                  </p>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-20px">
                <span v-if="forms.update_addressbook.phones.length > 1" class="delete_box">
                  <a @click="api.update_addressbook.send ? '' : forms.update_addressbook.phones.splice(index, 1)" class="cursor close_area">
                    <b-icon icon="trash" scale="1.3" variant="danger"></b-icon>
                  </a>
                </span>
              </div>
            </b-form-group>
            <p 
              v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.phones.$invalid) || api.update_addressbook.validation_errors.phones" 
              class="text text-danger animated bounceIntop"
            >
              <span v-if="!$v.forms.update_addressbook.phones.required">*phones is required</span>
              <span v-if="!$v.forms.update_addressbook.phones.minLength">*phones minimum {{$v.forms.update_addressbook.phones.$params.minLength.min}} is required</span>
            <span v-for="(em,i) in api.update_addressbook.validation_errors.phones" :key="i">{{ em }}</span>
            </p>
          </div>
        </div>
        <template v-if="forms.update_addressbook.show_more">
          <b-form-group label="job title" class="mb-0 mt-20px whiteBGinputWithGreyRoundedBorder">
            <b-form-input 
              type="text" 
              oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
              v-model="forms.update_addressbook.jobtitle" 
              :disabled="api.update_addressbook.send" 
              placeholder="Job Title" 
            />
            <p 
              v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.jobtitle.$invalid) || api.update_addressbook.validation_errors.jobtitle" 
              class="text text-danger animated bounceIntop"
            >
              <span v-if="!$v.forms.update_addressbook.jobtitle.valid">Job title is invalid</span>
              <span v-if="!$v.forms.update_addressbook.jobtitle.minLength">
                Job title should be more than {{$v.forms.update_addressbook.jobtitle.$params.minLength.min}} characters
              </span>
            <span v-for="(em,i) in api.update_addressbook.validation_errors.jobtitle" :key="i">{{ em }}</span>
            </p>
          </b-form-group>
          <b-form-group label=" Department" class="mb-0 mt-20px whiteBGinputWithGreyRoundedBorder">
            <b-form-input 
              type="text" 
              oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
              v-model="forms.update_addressbook.department" 
              :disabled="api.update_addressbook.send" 
              placeholder="Department" 
            />
            <p 
              v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.department.$invalid) || api.update_addressbook.validation_errors.department" 
              class="text text-danger animated bounceIntop"
            >
              <span v-if="!$v.forms.update_addressbook.department.valid">*Department is invalid</span>
              <span v-if="!$v.forms.update_addressbook.department.minLength">
                *Department should be more than {{$v.forms.update_addressbook.department.$params.minLength.min}} characters
              </span>
              <span v-for="(em,i) in api.update_addressbook.validation_errors.department" :key="i">{{ em }}</span>
            </p>
          </b-form-group>
          <b-form-group label="" class="mb-0 mt-20px whiteBGinputWithGreyRoundedBorder dropdownVersion">
            <vb-select 
              v-model="forms.update_addressbook.suffix" 
              :disabled="api.update_addressbook.send" 
              class="right-0 selectVoice-dropdown" 
              :defaultSelectedText="'Select suffix'" 
              :options="suffixs" 
            />
            <p 
              v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.suffix.$invalid) || api.update_addressbook.validation_errors.suffix" 
              class="text text-danger animated bounceIntop"
            >
              <span v-if="!$v.forms.update_addressbook.suffix.minLength">
                *suffix should be more than {{$v.forms.update_addressbook.suffix.$params.minLength.min}} characters
              </span>
            <span v-for="(em,i) in api.update_addressbook.validation_errors.suffix" :key="i">{{ em }}</span>
            </p>
          </b-form-group>
          <div class="add_fields add_email_box">
            <div class="d-flex flex-column">
              <b-form-group class="mb-0">
                <div class="d-flex justify-content-end w-100 mt-20px">
                  <a 
                    v-if="forms.update_addressbook.emails.length<=10"
                    class="newButton" 
                    @click="api.update_addressbook.send ? '' : forms.update_addressbook.emails.unshift({ emails: '' })"
                  >
                    <!-- <b-icon icon="plus-circle-fill" class="mr-3" variant="white"/> 
                    <span>Add email</span> -->
                    <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                    <span class="newButton-textPart text-white">Add email</span>
                  </a>
                </div>
              </b-form-group>
            </div>
            <div class v-for="(row, index) in forms.update_addressbook.emails" :key="index">
              <b-form-group class="mt-20px mb-0">
                <div class="d-flex align-items-center">
                  <div class="whiteBGinputWithGreyRoundedBorder flex-fill">
                    <input 
                      type="email" 
                      v-model="row.emails" 
                      :disabled="api.update_addressbook.send" 
                      class="d-inline-flex flex-grow-1 adjust-width add_new_email_row form-control" 
                      placeholder="Email" 
                    />
                    <p 
                      v-if="forms.update_addressbook.submitted && $v.forms.update_addressbook.emails.$each[index].emails.$invalid" 
                      class="text text-danger animated bounceIntop"
                    >
                      <span v-if="!$v.forms.update_addressbook.emails.$each[index].emails.email">* email is not valid</span>
                    </p>
                  </div>
                  <span class="delete_box m-2">
                    <a @click="api.update_addressbook.send ? '' : forms.update_addressbook.emails.splice(index, 1)" class="cursor close_area">
                      <b-icon icon="trash" font-scale="1.8" variant="danger" />
                    </a>
                  </span>
                </div>
              </b-form-group>
            </div>
            <p 
              v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.emails.$invalid) || api.update_addressbook.validation_errors.emails" 
              class="text text-danger animated bounceIntop"
            >
              <span v-for="(em,i) in api.update_addressbook.validation_errors.emails" :key="i">{{ em }}</span>
            </p>
          </div>
          <b-form-group class="mb-0 mt-20px whiteBGinputWithGreyRoundedBorder">
            <b-form-input 
              type="text" 
              oninput="this.value = this.value.replace(/[^a-zA-Z0-9\s]*$/gi, '')"
              v-model="forms.update_addressbook.note" 
              :disabled="api.update_addressbook.send" 
              placeholder="Note" 
            />
            <p 
              v-if="(forms.update_addressbook.submitted && $v.forms.update_addressbook.note.$invalid) || api.update_addressbook.validation_errors.note" 
              class="text text-danger animated bounceIntop"
            >
              <span v-if="!$v.forms.update_addressbook.note.valid">*Note is invalid</span>
              <span v-for="(em,i) in api.update_addressbook.validation_errors.note" :key="i">{{ em }}</span>
            </p>
          </b-form-group>
        </template>
        <div class="d-flex align-items-center justify-content-center md-mar-bottom md-mar-top">
          <a @click="api.update_addressbook.send ? '' : forms.update_addressbook.show_more = !forms.update_addressbook.show_more" class="dialer-link-more">
            {{ forms.update_addressbook.show_more ? "Hide" : "Show more . . ." }}
          </a>
        </div>
        <div class="d-flex justify-content-end">
          <button class="fullWidthDoneButton" :disabled="api.update_addressbook.send" type="submit">
            <vb-spinner v-if="api.update_addressbook.send" />
            <template v-else>Update</template>
          </button>
        </div>
      </b-form>
    </div>
    <SelectAddressBookNumberModal />
    <ProfileImageUploader modalName="AddressBookProfileImageUploader" @image-uploaded="onImageUploaded($event);" />
  </div>
</template>

<script>
import { required, minLength, email, maxLength, helpers } from "vuelidate/lib/validators";
import { events, VOIP_API, $fn } from "../../utils";
import SelectAddressBookNumberModal from "../Modals/SelectAddressBookNumberModal.vue";
import ProfileImageUploader from "../Modals/ProfileImageUploader.vue";
import { number_formater } from '@/filter';
import { mapGetters } from 'vuex';
import { GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters';
import { CountryName2, flagsCountryCode } from '@/utils/flags';
import _ from 'lodash';
import moment from 'moment';
const tabs = {
  local: 'local',
  global: 'global',
}
const screens = {
  list: 'list',
  add: 'add',
  update: 'update',
}
const suffixs = [
  'Mr', 
  'Miss', 
  'Mrs', 
  'Other'
]
const integrations = {
  hubspot: {
    name: 'HubSpot',
    icon: 'hubSpot-icon',
    desc: `V2 - HubSpot is an inbound marketing and sales platform that helps companies attract visitors, convert leads, and close customers.`,
    type: 'CRM',
    value: 'hubspot',
  },
  zoho: {
    name: 'ZOHO',
    icon: 'zoho-icon',
    desc: `End-to-end, fully customizable CRM solution for growing businesses and enterprises.`,
    type: 'CRM',
    value: 'zoho',
  },
  salesforce: {
    name: 'Sales Force',
    icon: 'salesForce-icon',
    desc: `Integrate all your phone calls & phone numbers into salesforce cloud. Get an integrated dialer & have your phone calls/text messages logged at the right place.`,
    type: 'CRM',
    value: 'salesforce',
  },
  copper: {
    name: 'Copper',
    icon: 'copper-icon',
    desc: `Copper organizes all your emails, calls, files, and notes for every contact—so you have a full view of that relationship history.`,
    type: 'CRM',
    value: 'copper',
  },
  pipedrive: {
    name: 'Pipedrive',
    icon: 'pipedrive-icon',
    desc: `Sync your pipedrive contacts. Create activities for your calls and text messages against your contacts and associated deals. Get click to call & text icons.`,
    type: 'CRM',
    value: 'pipedrive',
  },
  freshdesk: {
    name: 'Freshdesk',
    icon: 'freahdesk-icon',
    desc: `Sync your freshdesk contacts. Create activities for your calls and text messages against your contacts and associated deals. Get click to call & text icons.`,
    type: 'CRM',
    value: 'freshdesk',
  },
  intercom: {
    name: 'Intercom',
    icon: 'intercom-icon',
    desc: `Sync your intercom contacts. Create activities for your calls and text messages against your contacts and associated deals. Get click to call & text icons.`,
    type: 'CRM',
    value: 'intercom',
  },
}
export default {
  name: "Addressbooks",
  components: {
    SelectAddressBookNumberModal,
    ProfileImageUploader,
  },
  props: {
    tabSelected: {
      type: String,
      default: ''
    },
    openedFromMeetings: {
      type: Boolean,
      default: false
    }
  },
  inject: [
    'local_filess', 
    'isEmpty', 
    'getProperty',
    'getAddressBookLocal',
    'getAddressBookGlobal',
    'appNotify'
  ],
  data() {
    return {
      forms: {
        create_addressbook: this.$helperFunction.formInstance({
          data: {
            firstname: '',
            lastname: '',
            company: '',
            jobtitle: '',
            nickname: "nickname",
            department: '',
            suffix: '',
            note: '',
            emails: [],
            phones: [
              {
                country: 'GB',
                phone: "Main",
                number: "",
                shortcode: "",
              },
            ],
            is_global: false,
            show_more: false,
          }
        }),
        update_addressbook: this.$helperFunction.formInstance({
          data: {
            id: '',
            firstname: '',
            lastname: '',
            company: '',
            jobtitle: '',
            nickname: "nickname",
            department: '',
            suffix: '',
            note: '',
            emails: [],
            phones: [
              {
                country: 'GB',
                phone: "Main",
                number: "",
                shortcode: "",
              },
            ],
            is_global: false,
            show_more: false,
          }
        })
      },
      api: {
        fetch_shortcodes: this.$helperFunction.apiInstance(),
        fetch_local_addressbooks: this.$helperFunction.apiInstance(),
        fetch_global_addressbooks: this.$helperFunction.apiInstance(),
        cret_addressbook: this.$helperFunction.apiInstance(),
        create_addressbook: this.$helperFunction.apiInstance({
          validation_errors: true,
        }),
        update_addressbook: this.$helperFunction.apiInstance({
          validation_errors: true,
        }),
        delete_addressbook: this.$helperFunction.apiInstance({
          send: '',
        }),
        toggle_favourite: this.$helperFunction.apiInstance({
          send: [],
        }),
        fetch_integrated: this.$helperFunction.apiInstance(),
      },
      selected: {
        tab: tabs.local,
      },
      filter: {
        local_addressbooks: {
          search: '',
        },
        global_addressbooks: {
          search: '',
        },
      },
      response: {
        integrateds: [],
        shortcodes: [],
      },
      conditions: {
        screen: screens.list
      },
    };
  },
  computed: {
    ...mapGetters([
      GET_LOCAL_SETTING_IS_DARK_MODE,
      'getCurrentUser',
      'getIsMobile'
    ]),
    countries(){ 
      return _.orderBy(moment.tz.countries().map((i)=>({
        value: i,
        text: CountryName2[i],
        flag: flagsCountryCode[i],
      })) , ['text'], ['asc']);
    },
    tabs(){ return tabs },
    screens(){ return screens },
    suffixs(){ return suffixs },
    filterLocalAddressBooks(){
      const search = this.filter.local_addressbooks.search.toLowerCase().trim();
      return this.$store.state.common.address_books.local.data.filter((data) =>
        `${data.firstName} ${data.lastName} ${data.all_numbers} ${data.all_formated_numbers}`
          .toLowerCase()
          .trim()
          .includes(search)
      );
    },
    filterGlobalAddressBooks(){
      const search = this.filter.global_addressbooks.search.toLowerCase().trim();
      return this.$store.state.common.address_books.global.data.filter((data) =>
        `${data.firstName} ${data.lastName} ${data.all_numbers} ${data.all_formated_numbers}`
          .toLowerCase()
          .trim()
          .includes(search)
      );
    },
    editAddressBook(){
      const updated_id = this.forms.update_addressbook.id
      const local_addressbook = this.$store.state.common.address_books.local.data.find((addressbook) => addressbook.real_id == updated_id);
      const global_addressbook = this.$store.state.common.address_books.global.data.find((addressbook) => addressbook.real_id == updated_id);
      return local_addressbook || global_addressbook
    },
    filterShortCodes(){ 
      const phones = this.forms.update_addressbook.id?this.forms.update_addressbook.phones:this.forms.create_addressbook.phones
      const used_short_codes = phones?.map(i=>i.shortcode).filter(i=>!!i)
      return this.response.shortcodes.filter(i=>!used_short_codes.includes(i)) 
    },
    // firstShortCodesOption(){ return this.filterShortCodes?.[0] ?? '' },
  },
  validations: {
    forms: {
      create_addressbook: {
        firstname: {
          required: required,
          maxLength: maxLength(16),
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        suffix: {
          
        },
        lastname: {
          maxLength: maxLength(16),
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        company: {
          minLength: minLength(3),
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        jobtitle: {
          minLength: minLength(3),
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        department: {
          minLength: minLength(3),
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        emails: {
          $each: {
            emails: {
              email: email,
            },
          }
        },
        phones: {
          required: required,
          minLength: minLength(1),
          $each: {
            phone: {
              required: required,
            },
            number: {
              required: required,
              validNumber(value,obj){ return $fn.validPhoneNumber(value,obj.country) },
            },
            country: {
              // required: required,
            },
            shortcode: {
              // required: required,
            },
          }
        },
        nickname: {
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        note: {
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        is_global: {
          
        },
      },
      update_addressbook: {
        id: {
          required: required,
        },
        firstname: {
          required: required,
          maxLength: maxLength(16),
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        suffix: {
          
        },
        lastname: {
          maxLength: maxLength(16),
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        company: {
          minLength: minLength(3),
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        jobtitle: {
          minLength: minLength(3),
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        department: {
          minLength: minLength(3),
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        emails: {
          $each: {
            emails: {
              email: email,
            },
          }
        },
        phones: {
          required: required,
          minLength: minLength(1),
          $each: {
            phone: {
              required: required,
            },
            number: {
              required: required,
              validNumber(value,obj){ return $fn.validPhoneNumber(value,obj.country) },
            },
            country: {
              // required: required,
            },
            shortcode: {
              // required: required,
            },
          }
        },
        nickname: {
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        note: {
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
        },
        is_global: {
          
        },
      },
    },
  },
  filters: {
    countryImageByCode(i){ return flagsCountryCode[i] },
    countryNameByCode(i){ return CountryName2[i] },
    integrationIcons(value){return integrations[value]?.icon ?? ''},
  },
  watch: {
    "$store.state.global_conditions.add_addressbooks": {
      immediate: true,
      async handler(number){
        if (number) {
          this.conditions.screen=screens.add
          this.fetchAvailableShortCodes();
          await $fn.sleep(1.5 * 1000)
          if(typeof number == 'string'){
            const international_number = number_formater(number,'',true)
            const phoneNumber = $fn.getParsePhoneNumber('')(international_number)
            if(phoneNumber && phoneNumber.isValid()){
              this.forms.create_addressbook.phones[0].number=phoneNumber.formatNational()
              this.forms.create_addressbook.phones[0].country=phoneNumber.country
            }
          }
          // this.forms.create_addressbook.phones[0].number=number_formater(number,'',true)
          // this.forms.create_addressbook.phones[0].shortcode=this.firstShortCodesOption
        }
      },
    },
    "selected.tab"(tab) {
      if(tab==tabs.global){
        this.fetchGlobalAddressBooks()
      } else if(tab==tabs.local){
        this.fetchLocalAddressBooks()
      }
    },
    "conditions.screen"(new_screen,old_screen){
      if(old_screen==screens.update) {
        this.api.update_addressbook.reset()
        this.forms.update_addressbook.reset()
      } else if(old_screen==screens.add) {
        this.api.create_addressbook.reset()
        this.forms.create_addressbook.reset()
        this.$store.state.global_conditions.add_addressbooks=false
      }
    },
  },
  methods: {
    onInputNumberField(index){
      let obj
      if(this.conditions.screen==screens.add) obj = this.forms.create_addressbook.phones[index]
      else if(this.conditions.screen==screens.update) obj = this.forms.update_addressbook.phones[index]
      const phoneNumber = $fn.getParsePhoneNumber('')(obj.number,obj.country)
      // console.log(phoneNumber)
      if(phoneNumber){
        if(obj.country!=phoneNumber.country) {
          if(this.conditions.screen==screens.add) this.forms.create_addressbook.phones[index].country=phoneNumber.country
          else if(this.conditions.screen==screens.update) this.forms.update_addressbook.phones[index].country=phoneNumber.country
        }
      }
    },
    setUpdate(addressbook){
      if(!addressbook) return;
      const emails = addressbook.emails ? addressbook.emails?.split?.(";")?.map?.((email) => {
        return {
          emails: email,
        };
      }) ?? [] : [];
      const phones = addressbook.phones.map((phone) => {
        let number = ''
        let country = ''
        const international_number = number_formater(phone.number,'',true)
        const phoneNumber = $fn.getParsePhoneNumber('')(international_number)
        if(phoneNumber && phoneNumber.isValid()){
          number=phoneNumber.formatNational()
          country=phoneNumber.country
        }
        return {
          phone: phone.type,
          country: country,
          number: number,
          shortcode: phone.shortCode,
        };
      });
      const shortCodes = addressbook.phones.map((phone)=>phone.shortCode).filter(shortCode=>!!shortCode);
      this.forms.update_addressbook.id=addressbook.real_id ?? ''
      this.forms.update_addressbook.firstname=addressbook.firstName ?? ''
      this.forms.update_addressbook.lastname=addressbook.lastName ?? ''
      this.forms.update_addressbook.company=addressbook.company ?? ''
      this.forms.update_addressbook.jobtitle=addressbook.jobtitle ?? ''
      this.forms.update_addressbook.nickname=addressbook.nickname ?? ''
      this.forms.update_addressbook.department=addressbook.department ?? ''
      this.forms.update_addressbook.suffix=addressbook.suffix ?? ''
      this.forms.update_addressbook.note=addressbook.note ?? ''
      this.forms.update_addressbook.emails=emails ?? []
      this.forms.update_addressbook.phones=phones ?? []
      this.forms.update_addressbook.is_global=addressbook.global==1
      this.fetchAvailableShortCodes(shortCodes);
      this.conditions.screen=screens.update
    },
    onImageUploaded(image){
      const updated_id = this.forms.update_addressbook.id
      const index = this.$store.state.common.address_books.local.data.findIndex(addressbook=>addressbook.real_id==updated_id)
      const global_index = this.$store.state.common.address_books.global.data.findIndex(addressbook=>addressbook.real_id==updated_id)
      if(index>-1){
        this.$set(this.$store.state.common.address_books.local.data[index],'profile_image',image)
      } else if(global_index>-1){
        this.$set(this.$store.state.common.address_books.global.data[global_index],'profile_image',image)
      }
    },
    audioCall(addressbook) {
      if (this.api.delete_addressbook.send==addressbook.real_id) return;
      if (addressbook?.phones?.length < 2) {
        const number_data = addressbook.main_phone;
        if (!number_data) return;
        this.$root.$emit(events.audio_call, {
          number: number_data.number,
          id: number_data.uid,
          type: 'addressbook',
        });
      } else {
        this.$modal.show("SelectAddressBookNumberModal", {
          addressbook: addressbook,
        });
      }
    },
    async createAddressbook() {
      this.forms.create_addressbook.submitted = true;
      this.$v.forms.create_addressbook.$touch();
      if (this.api.create_addressbook.send || this.$v.forms.create_addressbook.$invalid) return;
      try {
        this.api.create_addressbook.send = true;
        this.api.create_addressbook.validation_errors = {};
        const phones = await this.forms.create_addressbook.phones.map(phone=>({
          ...phone,
          number: $fn.getNumberFormated(phone.number,phone.country)
        }))
        const { data: { data: address_book } } = await VOIP_API.endpoints.address_books.create({
          firstname: this.forms.create_addressbook.firstname,
          lastname: this.forms.create_addressbook.lastname,
          company: this.forms.create_addressbook.company,
          jobtitle: this.forms.create_addressbook.jobtitle,
          nickname: this.forms.create_addressbook.nickname,
          department: this.forms.create_addressbook.department,
          suffix: this.forms.create_addressbook.suffix,
          note: this.forms.create_addressbook.note,
          emails: this.forms.create_addressbook.emails,
          phones: phones,
          is_global: this.forms.create_addressbook.is_global,
          flag: this.forms.create_addressbook.is_global ? 'global' : 'local',
        })
        const _address_book = $fn.manipulateAddressBook(address_book)
        if(this.forms.create_addressbook.is_global){
          this.$store.state.common.address_books.global.total = this.$store.state.common.address_books.global.total + 1
          this.$store.state.common.address_books.global.data.unshift(_address_book)
          this.fetchGlobalAddressBooks();
        } else {
          this.$store.state.common.address_books.local.total = this.$store.state.common.address_books.local.total + 1
          this.$store.state.common.address_books.local.data.unshift(_address_book)
          this.fetchLocalAddressBooks();
        }
        this.$store.state.global_conditions.add_addressbooks = false;
        this.appNotify({
          message: "Successfully Added!",
          type: "success",
        })
        if(!this.openedFromMeetings) {
          this.conditions.screen=screens.list
        } else {
          this.$emit('created')
        }
        this.$root.$emit(events.fetch_call_activity)
      } catch (ex) {
        // console.log(ex)
        this.api.create_addressbook.validation_errors = ex.own_errors;
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.forms.create_addressbook.submitted = false;
        this.api.create_addressbook.send = false;
      }
    },
    async fetchAvailableShortCodes(shortCodes=[]) {
      try {
        this.api.fetch_shortcodes.send = true;
        const { data } = await VOIP_API.endpoints.address_books.shortcodes({
          type: this.selected.tab,
          accountcode: this.getCurrentUser?.account,
          uid: this.getCurrentUser?.uid,
        })
        this.response.shortcodes = [...data,...shortCodes];
      } finally {
        this.api.fetch_shortcodes.send = false;
      }
    },
    async updateAddressbook() {
      this.forms.update_addressbook.submitted = true;
      this.$v.forms.update_addressbook.$touch();
      if (this.api.update_addressbook.send || this.$v.forms.update_addressbook.$invalid) return;
      try {
        this.api.update_addressbook.send = true;
        this.api.update_addressbook.validation_errors = {};
        const phones = await this.forms.update_addressbook.phones.map(phone=>({
          ...phone,
          number: $fn.getNumberFormated(phone.number,phone.country)
        }))
        const { data: { data: address_book } } = await VOIP_API.endpoints.address_books.update({
          id: this.forms.update_addressbook.id,
          firstname: this.forms.update_addressbook.firstname,
          lastname: this.forms.update_addressbook.lastname,
          company: this.forms.update_addressbook.company,
          jobtitle: this.forms.update_addressbook.jobtitle,
          nickname: this.forms.update_addressbook.nickname,
          department: this.forms.update_addressbook.department,
          suffix: this.forms.update_addressbook.suffix,
          note: this.forms.update_addressbook.note,
          emails: this.forms.update_addressbook.emails,
          phones: phones,
          is_global: this.forms.update_addressbook.is_global,
          flag: this.forms.update_addressbook.is_global ? 'global' : 'local',
        })
        const _address_book = $fn.manipulateAddressBook(address_book)
        if(this.forms.update_addressbook.is_global){
          const addressbooks = await this.$store.state.common.address_books.global.data.map((item)=>item.real_id==this.forms.update_addressbook.id?_address_book:item)
          this.$store.state.common.address_books.global.data = addressbooks
        } else {
          const addressbooks = await this.$store.state.common.address_books.local.data.map((item)=>item.real_id==this.forms.update_addressbook.id?_address_book:item)
          this.$store.state.common.address_books.local.data = addressbooks
        }
        this.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
        this.conditions.screen=screens.list
        this.$root.$emit(events.fetch_call_activity)
      } catch (ex) {
        this.api.update_addressbook.validation_errors = ex.own_errors;
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.forms.update_addressbook.submitted = false;
        this.api.update_addressbook.send = false;
      }
    },
    async deleteAddressBook(addressbook) {
      const id = addressbook?.real_id ?? ''
      const is_global = addressbook?.global==1
      const name = `${addressbook?.firstName || ''} ${addressbook?.lastName || ''}`
      if (this.api.delete_addressbook.send || !id) return;
      try {
        this.api.delete_addressbook.send = addressbook.id;
        const { confirm } = await this.$appConfirmation({
          title: "warning",
          message: `Are you sure you want to delete this ${name} Contact`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        await VOIP_API.endpoints.address_books.delete({
          id: id,
          accountcode: this.getCurrentUser?.account,
          uid: this.getCurrentUser?.uid,
          flag: is_global ? 'global' : 'local',
        })
        if(is_global){
          this.$store.state.common.address_books.global.total = this.$store.state.common.address_books.global.total - 1
          this.$store.state.common.address_books.global.data = await this.$store.state.common.address_books.global.data.filter((item)=>item.real_id!=id);
        } else {
          this.$store.state.common.address_books.local.total = this.$store.state.common.address_books.local.total - 1
          this.$store.state.common.address_books.local.data = await this.$store.state.common.address_books.local.data.filter((item)=>item.real_id!=id);
        }
        this.appNotify({
          message: "Successfully Deleted!",
          type: "success",
        })
        this.$root.$emit(events.fetch_call_activity)
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.delete_addressbook.send = '';
      }
    },
    async toggleFavourite(addressbook){
      const id = addressbook?.real_id ?? ''
      const is_favourite = addressbook?.is_favourite
      const is_global = addressbook?.global==1
      if(!id || this.api.toggle_favourite.send.includes(id)) return;
      try {
        this.api.toggle_favourite.send.push(id)
        await VOIP_API.endpoints.address_books.toggleFavourite({
          id: id,
          action: is_favourite ? 'remove' : 'add'
        })
        if(is_global){
          const index = await this.$store.state.common.address_books.global.data.findIndex((item)=>item.real_id==id);
          this.$store.state.common.address_books.global.data[index].is_favourite=~~!is_favourite;
        } else {
          const index = await this.$store.state.common.address_books.local.data.findIndex((item)=>item.real_id==id);
          this.$store.state.common.address_books.local.data[index].is_favourite=~~!is_favourite;
        }
        this.appNotify({
          message: `Successfully ${is_favourite ? 'Un Favourite' : 'Favourite'}!`,
          type: "success",
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.toggle_favourite.send.splice(this.api.toggle_favourite.send.indexOf(id),1)
      }
    },
    async fetchLocalAddressBooks(){
      if(this.api.fetch_local_addressbooks.send) return;
      try {
        this.api.fetch_local_addressbooks.send=true
        await this.getAddressBookLocal()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_local_addressbooks.send=false
      }
    },
    async fetchGlobalAddressBooks(){
      if(this.api.fetch_global_addressbooks.send) return;
      try {
        this.api.fetch_global_addressbooks.send=true
        await this.getAddressBookGlobal()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_global_addressbooks.send=false
      }
    },
    onLocalAddressBookScroll(Event){
      const height = Event.target.scrollHeight;
      const top = Event.target.scrollTop;
      const offset_height = Event.target.offsetHeight;
      const scroll_bar_height = height - (height - offset_height);
      const scroll_bottom = Math.floor(height - (scroll_bar_height + top));
      if (scroll_bottom <= 5) {
        this.fetchLocalAddressBooks();
        Event.target.scrollTop=top-5
      }
    },
    onGlobalAddressBookScroll(Event){
      const height = Event.target.scrollHeight;
      const top = Event.target.scrollTop;
      const offset_height = Event.target.offsetHeight;
      const scroll_bar_height = height - (height - offset_height);
      const scroll_bottom = Math.floor(height - (scroll_bar_height + top));
      if (scroll_bottom <= 5) {
        this.fetchGlobalAddressBooks();
        Event.target.scrollTop=top-5
      }
    },
    async fetchIntegrated(){
      if(this.api.fetch_integrated.send) return;
      try {
        this.api.fetch_integrated.send=true
        const { data } = await VOIP_API.endpoints.integrations.integratedlist({
          accountcode: this.getCurrentUser.account
        })
        this.response.integrateds=data
      } catch(ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_integrated.send=false
      }
    },
    async openIntegrationContactDetail(addressbook){
      try {
        const { data: url } = await VOIP_API.endpoints.integrations.integratedContactDetailLink({
          accountcode: this.getCurrentUser.account,
          integration: addressbook.tags,
          contactId: addressbook.real_id,
        })
        if(!url) return;
        window.open(url,'_blank');
      } catch(ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_integrated.send=false
      }
    },
  },
  mounted(){
    this.fetchLocalAddressBooks()
    this.fetchGlobalAddressBooks()
    this.fetchIntegrated()

    if(this.openedFromMeetings){
      this.fetchAvailableShortCodes()
      this.conditions.screen = this.screens.add
      this.selected.tab = this.tabSelected
      if(this.tabSelected=='global') {
        this.forms.create_addressbook.is_global=true;
      } else {
        this.forms.create_addressbook.is_global=false;
      }
    }
  }
};
</script>
