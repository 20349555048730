<template>
  <div class="dialer-flex alt" @dblclick="!callObjInfo.incall && callObjInfo.incoming ? '' : $store.state.global_conditions.tab='video-call'" v-if="session.callState === 'incall' || session.callState === 'pending'">
    <template v-if="session.callState === 'incall'">
      <div id="active-sip-call" class="dialer-incommingVideoCall-info d-flex align-items-center" :call-state="session.callState">
        <div class="dialer-user-dp mr-2">
          <img width="40" height="40" class="rounded" :src="callObjInfo.image || ''" @error="$event.target.src=userDp" alt="user image" />
        </div>
        <div class="dialer-user-bio">
          <h6 v-if="callObjInfo.test_call" class="m-0">testing call</h6>
          <div v-else>
            From: {{ callObjInfo.from_name }} {{ callObjInfo.from_number | number_formater }}<br />
            To: {{ callObjInfo.to_name }} {{ callObjInfo.to_number | number_formater }}
            <template v-if="callObjInfo.caller_id">
              <br />
              {{ callObjInfo.caller_id | number_formater }}
            </template>
          </div>
          <div v-if="callObjInfo.call_type">{{ callObjInfo.call_type }}</div>
          <div class="d-flex align-items-center">
            <p class="m-0 pr-2">{{ session.time | incrementFilter }}</p>
            <b-icon icon="phone-alt" />
          </div>
        </div>
      </div>
      <slot name="switchCalls" />
      <vb-icon class="sm-mar-bottom cursor_pointer" v-if="session.feedback_screen_active" @click="session.hangup()" icon="dialer-callRejectInbox-icon" width="40" height="40" />
      <template v-else>
        <vb-icon class="sm-mar-bottom cursor_pointer" v-if="callObjInfo.is_transfer ? !callObjInfo.incall : true" @click="session.hangup()" icon="dialer-callRejectInbox-icon" width="40" height="40" />
        <vb-icon class="mr-1 sm-mar-bottom cursor_pointer makePathBlack" v-if="callObjInfo.is_transfer && callObjInfo.incall" @click="$store.state.sip.phone.customAfterTransfer();" icon="duringCall-afterTransfer-icon" width="40" height="40"/>
        <vb-icon class="ml-1 sm-mar-bottom cursor_pointer makePathBlack" v-if="callObjInfo.is_transfer && callObjInfo.incall" @click="$store.state.sip.phone.cutomCancelTransfer()" icon="duringCall-cancelTransfer-icon" width="40" height="40" />
      </template>
    </template>
    <template v-if="session.callState === 'pending'">
      <!-- <div class="dialer-incommingVideoCall-info d-flex align-items-center" :call-state="session.callState">
        <div class="dialer-user-dp mr-2">
          <img width="40" height="40" class="rounded" :src="callObjInfo.image || ''" @error="$event.target.src=userDp" alt="user image" />
        </div>
        <div class="dialer-user-bio">
          <h6 class="text-capitalize mb-0" v-if="session.direction != 'out'">incoming Audio Call</h6>
          <h6 class="text-capitalize mb-0" v-else>Dialing Audio Call</h6>
          <div class>
            <h6 v-if="session.number=='*154*'" class="m-0">testing call</h6>
            <div v-else>
              From: {{ callObjInfo.from_name }} {{ callObjInfo.from_number | number_formater }}<br />
              To: {{ callObjInfo.to_name }} {{ callObjInfo.to_number | number_formater }}
            </div>
            <div v-if="callObjInfo.call_type">{{ callObjInfo.call_type }}</div>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <slot name="switchCalls" />
        <vb-icon @click="onAccept" v-if="session.direction != 'out'" class="cursor_pointer" icon="dialer-callAcceptInbox-icon" width="40" height="40" />
        <vb-icon class="cursor_pointer md-mar-left" @click="onReject" icon="dialer-callRejectInbox-icon" width="40" height="40" />
      </div> -->
      <div class="d-flex flex-column w-100">
        <div class="d-flex align-items-center justify-content-between">
          <div class="dialer-incommingVideoCall-info d-flex align-items-center" :call-state="session.callState">
            <div class="dialer-user-dp mr-2">
              <img width="40" height="40" class="rounded" :src="callObjInfo.image || ''" @error="$event.target.src=userDp" alt="user image" />
            </div>
            <div class="dialer-user-bio">
              <h6 class="text-capitalize mb-0" v-if="session.direction != 'out'">incoming Audio Call</h6>
              <h6 class="text-capitalize mb-0" v-else>Dialing Audio Call</h6>
              <div class>
                <h6 v-if="session.number=='*154*'" class="m-0">testing call</h6>
                <div v-else>
                  From: {{ callObjInfo.from_number | number_formater }}
                  <span class="call-info-tag-112233" v-if="callObjInfo.from_name">{{ callObjInfo.from_name }}</span>
                  <span class="call-info-tag-112233" v-if="callObjInfo.from_other">{{ callObjInfo.from_other }}</span>
                  <span class="call-info-tag-112233" v-if="callObjInfo.cids.from.caller_id">{{ callObjInfo.cids.from.caller_id | number_formater }}</span>
                  <span class="call-info-tag-112233" v-if="callObjInfo.cids.from.label">{{ callObjInfo.cids.from.label }}</span>
                  <span class="call-info-tag-112233" v-if="callObjInfo.from_integrated_platform">{{ callObjInfo.from_integrated_platform }}</span>
                  <vb-icon v-if="callObjInfo.from_integrated_platform" :icon="callObjInfo.from_integrated_platform | integrationIcons" class="integrationType-icon" />
                  <br />
                  To: {{ callObjInfo.to_number | number_formater }}
                  <span class="call-info-tag-112233" v-if="callObjInfo.to_name">{{ callObjInfo.to_name }}</span>
                  <span class="call-info-tag-112233" v-if="callObjInfo.to_other">{{ callObjInfo.to_other }}</span>
                  <span class="call-info-tag-112233" v-if="callObjInfo.cids.to.caller_id">{{ callObjInfo.cids.to.caller_id | number_formater }}</span>
                  <span class="call-info-tag-112233" v-if="callObjInfo.cids.to.label">{{ callObjInfo.cids.to.label }}</span>
                  <span class="call-info-tag-112233" v-if="callObjInfo.to_integrated_platform">{{ callObjInfo.to_integrated_platform }}</span>
                  <vb-icon v-if="callObjInfo.to_integrated_platform" :icon="callObjInfo.to_integrated_platform | integrationIcons" class="integrationType-icon" />
                </div>
                <div v-if="callObjInfo.call_type">{{ callObjInfo.call_type }}</div>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <template v-if="session.direction == 'out'">
              <slot name="switchCalls" />
            </template>
            <vb-icon @click="onAccept()" v-if="session.direction != 'out'" class="cursor_pointer" icon="dialer-callAcceptInbox-icon" width="40" height="40" />
            <vb-icon class="cursor_pointer md-mar-left" @click="session.hangup()" icon="dialer-callRejectInbox-icon" width="40" height="40" />
          </div>
        </div>
        <template v-if="session.direction != 'out'">
          <slot name="switchCalls" />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { 
  LOGGER, 
  events, 
  // VOIP_API 
} from "../../utils";
import _ from "lodash";
import { electron_events } from '../../electron/events';
import { call_info } from '@/mixin';
import { mapGetters } from 'vuex';
const integrations = {
  hubspot: {
    name: 'HubSpot',
    icon: 'hubSpot-icon',
    desc: `V2 - HubSpot is an inbound marketing and sales platform that helps companies attract visitors, convert leads, and close customers.`,
    type: 'CRM',
    value: 'hubspot',
  },
  zoho: {
    name: 'ZOHO',
    icon: 'zoho-icon',
    desc: `End-to-end, fully customizable CRM solution for growing businesses and enterprises.`,
    type: 'CRM',
    value: 'zoho',
  },
  salesforce: {
    name: 'Sales Force',
    icon: 'salesForce-icon',
    desc: `Integrate all your phone calls & phone numbers into salesforce cloud. Get an integrated dialer & have your phone calls/text messages logged at the right place.`,
    type: 'CRM',
    value: 'salesforce',
  },
  copper: {
    name: 'Copper',
    icon: 'copper-icon',
    desc: `Copper organizes all your emails, calls, files, and notes for every contact—so you have a full view of that relationship history.`,
    type: 'CRM',
    value: 'copper',
  },
  pipedrive: {
    name: 'Pipedrive',
    icon: 'pipedrive-icon',
    desc: `Sync your pipedrive contacts. Create activities for your calls and text messages against your contacts and associated deals. Get click to call & text icons.`,
    type: 'CRM',
    value: 'pipedrive',
  },
  freshdesk: {
    name: 'Freshdesk',
    icon: 'freahdesk-icon',
    desc: `Sync your freshdesk contacts. Create activities for your calls and text messages against your contacts and associated deals. Get click to call & text icons.`,
    type: 'CRM',
    value: 'freshdesk',
  },
  intercom: {
    name: 'Intercom',
    icon: 'intercom-icon',
    desc: `Sync your intercom contacts. Create activities for your calls and text messages against your contacts and associated deals. Get click to call & text icons.`,
    type: 'CRM',
    value: 'intercom',
  },
}
export default {
  name: "SipSession",
  mixins: [
    call_info
  ],
  props: {
    session: {
      type: Object,
      default: ()=>({}),
    }
  },
  data() {
    return {
      userDp: require("@/assets/images/user-male.png"),
      isInCall: false,
    };
  },
  filters: {
    incrementFilter(value) {
      if (!value) {
        return null;
      }
      if (value.substring(0, 2) == "00") {
        return value.substring(3);
      }
      return value;
    },
    integrationIcons(value){return integrations[value]?.icon ?? ''},
  },
  computed: {
    ...mapGetters([
      'isSwitchAbleState',
    ]),
    callObj(){ return this.session },
  },
  methods: {
    checkIn(type) {
      // console.log(type);
      if (type == "addressbook") {
        if (this.name[4]) {
          return "yes";
        }
        return "no";
      } else if (type == "speed") {
        if (this.name[3]) {
          return "yes";
        }
        return "no";
      } else if (type == "ringroup") {
        if (this.name[0] == "RINGGROUP") {
          return "yes";
        }
        return "no";
      }
    },
    onAccept() {
      const uuid = this.session.uuid
      if(!this.isSwitchAbleState) return;
      this.session.accepts(()=>{
        this.$store.state.calls.active_call=uuid
        this.$store.state.global_conditions.tab='video-call'
      });
    },
    test() {
      for (var x = 0; x < this.$store.state.calls.videoCalls.length; x++) {
        this.$store.state.calls.videoCalls[x].isPlay = false;
      }
      // console.log("yes im on right function");
      // this.$root.$refs.dialpad.getIsUpgradeable(session.number);
      if (window.api) {
        window.api.executeCommand("toggleAudio");
        window.api.executeCommand("toggleVideo");
      }
    },
    onReject() {
      this.session.hangup();
    },
    onHangup() {
      this.session.hangup();
    },
    endTimer() {
      const uid = _.get(this.session, "extra_data.uid");
      if (!uid) return;
      this.$set(this.$store.state.call_activity_timer, uid, {
        ...this.$store.state.call_activity_timer[uid],
        end_time: new Date(),
      });
    },
    reCallActivity() {
      let vm = this
      vm.$root.$emit(events.fetch_call_activity_interval,true)
    },
    onMissedCall(event){
      // console.log('onMissedCall')
      if(process.env.IS_ELECTRON){
        window.electron.send(electron_events.INCOMING_CALL_HIDE,{id:event.session.uuid})
      }
    },
    onRejectCall(event){
      // console.log('onRejectCall')
      if(process.env.IS_ELECTRON){
        window.electron.send(electron_events.INCOMING_CALL_HIDE,{id:event.session.uuid})
      }
    },
    onAcceptedCall(event){
      // console.log('onAcceptedCall')
      if(process.env.IS_ELECTRON){
        window.electron.send(electron_events.INCOMING_CALL_HIDE,{id:event.session.uuid})
      }
    },
    onSipCallRejectedByModal(message){
      LOGGER.log('onSipCallRejectedByModal',message)
      const { id } = message || {}
      if(this.session.uuid==id){
        this.onReject()
      }
    },
    onSipCallAcceptedByModal(message){
      LOGGER.log('onSipCallAcceptedByModal',message)
      const { id } = message || {}
      if(this.session.uuid==id){
        this.onAccept()
      }
    },
  },
  created(){
    // this.session.on(this.session.events.call_missed,this.onMissedCall)
    // this.session.on(this.session.events.call_rejected,this.onRejectCall)
    // this.session.on(this.session.events.call_accepted,this.onAcceptedCall)
    // if(process.env.IS_ELECTRON){
    //   window.electron.receive(electron_events.recieve.CALL_REJECTED,this.onSipCallRejectedByModal)
    //   window.electron.receive(electron_events.recieve.CALL_ACCEPTED,this.onSipCallAcceptedByModal)
    // }
  },
  beforeDestroy() {
    this.endTimer();
    this.reCallActivity();
    // this.session.off(this.session.events.call_missed,this.onMissedCall)
    // this.session.off(this.session.events.call_rejected,this.onRejectCall)
    // this.session.off(this.session.events.call_accepted,this.onAcceptedCall)
  },
};
</script>

<style lang="scss">
.call-info-tag-112233 {
  background-color: #000000;
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 3px;
  font-size: 9px;
  font-weight: 600;
  margin-left: 8px;
  line-height: 12px;
}
</style>