<template>
  <transition name="slide-fade">
    <template v-if="true">
      <div 
        v-if="!$store.getters.isIntegration" 
        id="panel" 
        :class="['panel h-100 make-panel-responsive CallLogInCallActivity v2',GET_LOCAL_SETTING_DASHBOARD.design.width == 25 ? 'width-is-25' : '',GET_LOCAL_SETTING_DASHBOARD.design.width > 25 ? 'widthGreaterThan25' : '',$store.getters.getIsMobile ? 'IsMobile' : '']">
        <div class="panel-inner">
          <h2 class="d-flex flex-wrap align-items-center justify-content-between mb-3">
            <span class="d-block title">Activity</span>
            <div class="d-flex align-items-center list-icon-container">
              <div v-if="!$store.getters.getIsMobile" class="callActivity-search-container">
                <input type="text" placeholder="Search" :class="['hide-callActivity-search',show_search ? 'show-callActivity-search' : '']" :disabled="api_sent.call_Activity" v-model="filter.call_activity.search" @input="onSearchCallActivity" />
                <vb-icon :class="['callActivity-search-icon',show_search ? 'move' : '']" width="18.979" height="17.257" icon="callActivity-search" @click="show_search = !show_search"/>
              </div>
              <div app-tour-web-dashboard-dialer-design-adjust class="adjust-design-dd">
                <b-dropdown id="dropdown-form" no-caret offset="25"  ref="dropdown" class="m-2 dd-text-dark " app-tour-step="2">
                  <template #button-content>
                    <vb-icon v-b-popover.hover.left.html="`<small>Adjust design</small>`" icon="callActivity-AdjustDesign-v3" class="callActivity-AdjustDesign-v3" width="18.979" height="17.257" />
                  </template>
                  <b-dropdown-form class="mb-0 ">
                    <b-form-group v-if="$store.getters.getIsMobile ?  false : true" class="mb-0 ">
                      <b-form-checkbox 
                        class="dialer-switch ml-5 cursor_pointer2" 
                        :checked="GET_LOCAL_SETTING_DASHBOARD.design.flip" 
                        @change="SET_LOCAL_SETTING_DASHBOARD_DESIGN_FLIP(!GET_LOCAL_SETTING_DASHBOARD.design.flip)" 
                        name="check-button" 
                        switch
                      >
                        Flip
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group class="mb-0 ">
                      <b-form-checkbox 
                        class="dialer-switch ml-5 cursor_pointer2" 
                        :checked="GET_LOCAL_SETTING_DASHBOARD.design.tags_show" 
                        @change="SET_LOCAL_SETTING_DASHBOARD_DESIGN_TAGS_SHOW(!GET_LOCAL_SETTING_DASHBOARD.design.tags_show)" 
                        name="check-button" 
                        switch
                      >
                        Tags and note show
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group class="mb-0 ">
                      <b-form-checkbox 
                        class="dialer-switch ml-5 cursor_pointer2" 
                        :checked="GET_LOCAL_SETTING_DASHBOARD.design.scroll_top" 
                        @change="SET_LOCAL_SETTING_DASHBOARD_DESIGN_SCROLL_TOP(!GET_LOCAL_SETTING_DASHBOARD.design.scroll_top)" 
                        name="check-button" 
                        switch
                      >
                        Scroll top
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group class="mb-0 ">
                      <b-form-checkbox 
                        class="dialer-switch ml-5 cursor_pointer2" 
                        :checked="GET_LOCAL_SETTING_DASHBOARD.design.transfer_log" 
                        @change="SET_LOCAL_SETTING_DASHBOARD_DESIGN_TRANSFER_LOGS(!GET_LOCAL_SETTING_DASHBOARD.design.transfer_log)" 
                        name="check-button" 
                        switch
                      >
                        Log detail
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group class="mb-0 ">
                      <b-form-checkbox :disabled="GET_LOCAL_SETTING_DASHBOARD.design.width==25"
                        class="dialer-switch ml-5 cursor_pointer2" 
                        :checked="compact"
                        @change="GET_LOCAL_SETTING_DASHBOARD.design.width==25 ? compact=false: compact = !compact" 
                        name="check-button" 
                        switch
                      >
                        Compact 
                      </b-form-checkbox>
                    </b-form-group>
                    <b-form-group class="mb-0 ">
                      <b-form-checkbox
                        class="dialer-switch ml-5 cursor_pointer2" 
                        v-model="conditions.new_logs"
                        name="check-button" 
                        switch
                      >
                        New Logs 
                      </b-form-checkbox>
                    </b-form-group>
                  </b-dropdown-form>
                  <b-dropdown-divider v-if="$store.getters.getIsMobile ?  false : true" />
                  <b-dropdown-form v-if="$store.getters.getIsMobile ?  false : true" class="mb-0">
                    <b-form-group class="mb-0 d-flex justify-content-center">
                      <b-form-radio-group :checked="GET_LOCAL_SETTING_DASHBOARD.design.width" @change="SET_LOCAL_SETTING_DASHBOARD_DESIGN_WIDTH($event);GET_LOCAL_SETTING_DASHBOARD.design.width==25 ? compact=false : ''">
                        <b-form-radio class="mx-2" :value="25">25%</b-form-radio>
                        <b-form-radio class="mx-2" :value="50">50%</b-form-radio>
                        <b-form-radio class="mx-2" :value="75">75%</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                  </b-dropdown-form>
                </b-dropdown>
              </div>
              
              <div app-tour-web-dashboard-dialer-call-logs-filter class="dialer-dropdown dialPanel_filter_dropdown ml-3 bg-white"  app-tour-step="3">
                <b-dropdown id="dropdown-form" text="Filter by" no-caret dropright offset="25"  ref="dropdown" class="m-2">
                  <template #button-content>
                    <vb-icon v-b-popover.hover.top.html="`<small>Filter call logs</small>`" icon="callActivity-filter" width="18.979" height="17.257" />
                  </template>
                  <b-dropdown-form>
                    <b-form-checkbox :checked="filter.call_activity.allchecked" @change="filter.call_activity.toggleallcheck();">{{ filter.call_activity.allchecked ? "Uncheck all" : "Check all" }}</b-form-checkbox>
                    <b-form-checkbox :checked="filter.call_activity.teams" @change="filter.call_activity.teams = !filter.call_activity.teams">Teams</b-form-checkbox>
                    <b-form-checkbox :checked="filter.call_activity.shared_voicemail" @change="filter.call_activity.shared_voicemail = !filter.call_activity.shared_voicemail">Shared voicemail</b-form-checkbox>
                    <b-form-checkbox :checked="filter.call_activity.voicemail" @change="filter.call_activity.voicemail = !filter.call_activity.voicemail">Voicemail</b-form-checkbox>
                    <b-form-checkbox :checked="filter.call_activity.recording" @change="filter.call_activity.recording = !filter.call_activity.recording">Recording</b-form-checkbox>
                    <b-form-checkbox :checked="filter.call_activity.sms" @change="filter.call_activity.sms = !filter.call_activity.sms">SMS</b-form-checkbox>
                    <b-dropdown-group header="Video">
                      <b-form-checkbox :checked="filter.call_activity.video.missed" @change="filter.call_activity.video.missed = !filter.call_activity.video.missed">Missed</b-form-checkbox>
                      <b-form-checkbox :checked="filter.call_activity.video.dial" @change="filter.call_activity.video.dial = !filter.call_activity.video.dial">Dial</b-form-checkbox>
                      <b-form-checkbox :checked="filter.call_activity.video.received" @change="filter.call_activity.video.received = !filter.call_activity.video.received">Received</b-form-checkbox>
                    </b-dropdown-group>
                    <b-dropdown-group header="Audio">
                      <b-form-checkbox :checked="filter.call_activity.audio.missed" @change="filter.call_activity.audio.missed = !filter.call_activity.audio.missed">Missed</b-form-checkbox>
                      <b-form-checkbox :checked="filter.call_activity.audio.dial" @change="filter.call_activity.audio.dial = !filter.call_activity.audio.dial">Dial</b-form-checkbox>
                      <b-form-checkbox :checked="filter.call_activity.audio.received" @change="filter.call_activity.audio.received = !filter.call_activity.audio.received">Received</b-form-checkbox>
                    </b-dropdown-group>
                  </b-dropdown-form>
                </b-dropdown>
              </div>

              <div app-tour-web-dashboard-dialer-call-logs-reload class="ml-3 d-flex align-items-center" app-tour-step="4">
                <button class="" id="refresh-button" v-if="refresh_sec == '0'" @click="fetchCallActivity()" :class="`${api_sent.call_Activity?'rotate-animation':''}`">
                  <vb-icon icon="callActivity-refresh" width="18.979" height="17.257" />
                </button>
                <b-popover target="refresh-button" placement="right" triggers="hover">
                  <small>Refresh call logs</small>
                </b-popover>
              </div>

              <template v-if="!isProduction">
                
                <div class="ml-3 d-flex align-items-center">
                  <button @click="testConfirmation()">
                    <b-icon icon="pencil" />
                  </button>
                </div>
                <div class="ml-3 d-flex align-items-center">
                  <button class="" id="test-notification" @click="notificationTest()">
                    <b-icon style="vertical-align:middle;" variant="primary" icon="arrow-clockwise" scale="1.1" />
                  </button>
                  <b-popover target="test-notification" placement="right" triggers="hover">
                    <small>Test notification</small>
                  </b-popover>
                </div>

              </template>
              
            
            </div>

          </h2>
          <div v-if="$store.getters.getIsMobile" class="callActivity-search-container">
            
            <input type="text" :class="['hide-callActivity-search',show_search ? 'show-callActivity-search' : '']" :disabled="api_sent.call_Activity" v-model="filter.call_activity.search" @input="onSearchCallActivity" />
            <b-icon :class="['callActivity-search-icon',show_search ? 'move' : ''] " icon="search" @click="show_search = !show_search"/>
          </div>

          <div class="list-area">
            <div class="holder">
              
              <ul v-if="conditions.new_logs" id="newLogsPhoneListScroll" class="phone-list allow_scroll list-unstyled scrollOnHOver" @scroll="onScroll($event)">
                <div v-if="newCallActivities.length < 1 && api.fetch_call_activity.send" class="d-flex justify-content-center align-items-center h-100 w-100">
                  <vb-loading type="ellipseV2" />
                </div>
                <div class="h-auto">
                  <div v-if="!isEmpty(newCallActivities)" class="callActivity_overlayTop"></div>
                  <template v-for="(call, index) in newCallActivities">
                    <h3 :key="`${index}-date-call-activity`" class="pb-1 ddd" v-if="call.own_date">
                      <span style="color:black;font-size:12px;"> {{ call.own_date | filter_date_current(null, { unix: true }) }} </span>
                    </h3>
                    <li :key="index">
                      <CallActivityTestItem :call="call" />
                    </li>
                  </template>
                  <div class="callActivity_overlayBottom" v-if="!isEmpty(filteredCallActivity)"></div>
                </div>
                <div>
                  <vb-loading type="ellipseV2" v-if="api_sent.call_Activity" />
                  <template v-else-if="isEmpty(filteredCallActivity)">
                    <div class="emptyScreenContainer allTransparent pt-0">
                      <img width="65%" style="max-width: 380px;" height="auto" class="mt-20px" v-if="GET_LOCAL_SETTING_IS_DARK_MODE" :src="require('@/assets/images/emptyScreenImages/darkMode/forCallLogs.png')"/>
                      <img width="65%" style="max-width: 380px;" height="auto" class="mt-20px" v-else :src="require('@/assets/images/emptyScreenImages/forCallLogs.png')"/>
                      <div class="emptyScreenContainer-heading inCallLogs mt-50px">
                        Welcome aboard to VoIP Business!
                      </div>
                      <div class="emptyScreenContainer-text inCallLogs w-100">
                        Are you ready to unleash unstoppable productivity? Take the plunge and make your first call now!
                        Alternatively, give our test call a try to ensure everything's set up just right.
                      </div>
                      <button @click="testCall()" class="newDoneButton mt-20px">
                        <vb-icon icon="squared-call-icon" height="38px" width="38px" />
                        <span class="newDoneButton-textPart">Make a call</span>
                      </button>
                      <div class="emptyScreenContainer-heading inCallLogs mt-50px">
                        Quick start guide.
                      </div>
                      <div class="emptyScreenContainer-text inCallLogs w-75">
                        Use quick start to get to know the app and set up your organisation correctly.
                      </div>
                      <button class="newButton mt-20px">
                        <vb-icon icon="squared-quickStart-icon" height="38px" width="38px" />
                        <span class="newButton-textPart">Quick start</span>
                      </button>
                    </div>
                  </template>
                  <div 
                    v-else-if="$store.state.common.call_activity.is_completed"
                    class="NoMoreRecordText" 
                    style="display: flex;justify-content: center;align-items: center;margin-top: 25px;" 
                  >
                    No more record
                  </div>
                </div>
              </ul>
              <ul v-else id="phoneListScroll" class="phone-list allow_scroll list-unstyled scrollOnHOver" @scroll="onScroll">
                <div v-if="filteredCallActivity.length < 1 && api_sent.call_Activity" class="d-flex justify-content-center align-items-center h-100 w-100">
                  <vb-loading type="ellipseV2" v-if="true" />
                </div>
                <div class="h-auto">
                  <div v-if="!isEmpty(filteredCallActivity)" class="callActivity_overlayTop"></div>
                  <template v-for="(call, index) in filteredCallActivity">
                    <h3 :key="`${index}-date-call-activity`" class="pb-1 ddd" v-if="call.isDateShow">
                      <span style="color:black;font-size:12px;"> {{ (call.call_date || call.time) | dateFormate }} </span>
                    </h3>
                    <li :key="index">
                      <CallActivityItem1 
                        @click-play="$emit('click-play',$event)"
                        :class="`${compact ? 'compactVersion' : ''}`"
                        :call="call" 
                        :conditions="{
                          tag: GET_LOCAL_SETTING_DASHBOARD.design.tags_show,
                          note: GET_LOCAL_SETTING_DASHBOARD.design.tags_show,
                        }" 
                        @onClickInfo="$modal.show('CallInfoModal',{
                          callInfo: call
                        })" 
                        @onClickInfo1="$modal.show('CallInfoModalNew',{
                          callInfo: call,
                          ...$event ?? {},
                        })" 
                        @openOnlyTranscriptModal="$modal.show('AITranscript',{ call: call })"
                      />
                    </li>
                  </template>
                  <div class="callActivity_overlayBottom" v-if="!isEmpty(filteredCallActivity)"></div>
                </div>
                <div>
                  <vb-loading type="ellipseV2" v-if="api_sent.call_Activity" />
                  <template v-else-if="isEmpty(filteredCallActivity)">
                    <div class="emptyScreenContainer allTransparent pt-0">
                      <img width="65%" style="max-width: 380px;" height="auto" class="mt-20px" v-if="GET_LOCAL_SETTING_IS_DARK_MODE" :src="require('@/assets/images/emptyScreenImages/darkMode/forCallLogs.png')"/>
                      <img width="65%" style="max-width: 380px;" height="auto" class="mt-20px" v-else :src="require('@/assets/images/emptyScreenImages/forCallLogs.png')"/>
                      <div class="emptyScreenContainer-heading inCallLogs mt-50px">
                        Welcome aboard to VoIP Business!
                      </div>
                      <div class="emptyScreenContainer-text inCallLogs w-100">
                        Are you ready to unleash unstoppable productivity? Take the plunge and make your first call now!
                        Alternatively, give our test call a try to ensure everything's set up just right.
                      </div>
                      <button @click="testCall()" class="newDoneButton mt-20px">
                        <vb-icon icon="squared-call-icon" height="38px" width="38px" />
                        <span class="newDoneButton-textPart">Make a call</span>
                      </button>
                      <div class="emptyScreenContainer-heading inCallLogs mt-50px">
                        Quick start guide.
                      </div>
                      <div class="emptyScreenContainer-text inCallLogs w-75">
                        Use quick start to get to know the app and set up your organisation correctly.
                      </div>
                      <button class="newButton mt-20px">
                        <vb-icon icon="squared-quickStart-icon" height="38px" width="38px" />
                        <span class="newButton-textPart">Quick start</span>
                      </button>
                    </div>
                  </template>
                  <div 
                    v-else-if="$store.state.common.call_activity.is_completed"
                    class="NoMoreRecordText" 
                    style="display: flex;justify-content: center;align-items: center;margin-top: 25px;" 
                  >
                    No more record
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <CallInfoModal />
        <CallInfoModalNew modalName="CallInfoModalNew" @latest-record="fetchCallActivity()" />
        <WriteCallNoteModal @updated="fetchCallActivity()" />
        <TagCallNoteModal @updated="fetchCallActivity()" />
        <TagsAndNotesUpdateModal @updated="fetchCallActivity()" />
        <DirectChatModal />
        <AITranscriptModal />
      </div>
    </template>
  </transition>
</template>

<script>
import DirectChatModal from "../../Modals/chat/DirectChatModal.vue";
import { TokenService } from "@/services/storage.service";
import _ from "lodash";
import CallActivityItem1 from "./Item1.vue";
import moment from "moment-timezone";
import { LOGGER, events, VOIP_API, $fn } from "../../../utils";
import WriteCallNoteModal from "../../Modals/WriteCallNoteModal.vue";
import TagCallNoteModal from "../../Modals/TagCallNoteModal.vue";
import CallInfoModal from "../../Modals/CallInfoModal.vue";
import CallInfoModalNew from "../../Modals/CallInfoModalNew.vue";
import TagsAndNotesUpdateModal from '../../Modals/TagsAndNotesUpdateModal.vue'
import CallActivityTestItem from "../CallActivity/TestItem/index.vue";
import Timer from "timer.js";
import { showTime } from '@/filter'
import { mapGetters, mapMutations } from 'vuex';
import { GET_LOCAL_SETTING_DASHBOARD, GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters';
import { SET_LOCAL_SETTING_DASHBOARD_DESIGN_CALL_INFO_WIDTH, SET_LOCAL_SETTING_DASHBOARD_DESIGN_FLIP, SET_LOCAL_SETTING_DASHBOARD_DESIGN_SCROLL_TOP, SET_LOCAL_SETTING_DASHBOARD_DESIGN_TAGS_SHOW, SET_LOCAL_SETTING_DASHBOARD_DESIGN_TRANSFER_LOGS, SET_LOCAL_SETTING_DASHBOARD_DESIGN_WIDTH } from '@/store/helper/mutations';
import AITranscriptModal from '@/components/Modals/AITranscriptModal.vue';
export default {
  name: "DialPanel",
  components: {
    CallInfoModal,
    CallInfoModalNew,
    CallActivityItem1,
    WriteCallNoteModal,
    TagCallNoteModal,
    DirectChatModal,
    TagsAndNotesUpdateModal,
    AITranscriptModal,
    CallActivityTestItem,
  },
  inject: [
    'appNotify',
    'isEmpty',
  ],
  props: {
    isCallScreen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show_search : false,
      timer: null,
      time: 0,
      refresh_sec: "0",
      api_sent: {
        delete: "",
        call_Activity: false,
      },
      api_source: {
        call_Activity: null,
      },
      filter: {
        call_activity: {
          teams: true,
          shared_voicemail: true,
          voicemail: true,
          recording: true,
          sms: true,
          video: {
            missed: true,
            dial: true,
            received: true,
          },
          audio: {
            missed: true,
            dial: true,
            received: true,
          },
          get allchecked(){
            return (
              this.teams &&
              this.shared_voicemail &&
              this.voicemail &&
              this.recording &&
              this.sms &&
              this.audio.missed &&
              this.audio.dial &&
              this.audio.received &&
              this.video.missed &&
              this.video.dial &&
              this.video.received
            )
          },
          toggleallcheck(){
            this.teams =
            this.shared_voicemail =
            this.voicemail =
            this.recording =
            this.sms =
            this.audio.missed =
            this.audio.dial =
            this.audio.received =
            this.video.missed =
            this.video.dial =
            this.video.received = !this.allchecked
          },
          search: '',
        },
      },
      conditions: {
        new_logs: false,
      },
      api: {
        fetch_call_activity: this.$helperFunction.apiInstance()
      },
      response: {
        call_activity: [],
      },
      pagination: {
        call_activity: {
          per_page: 10,
          page: 1,
          completed: false,
        },
      },
      compact: false,
      windowInnerWidth: 1920,
    };
  },
  computed: {
    ...mapGetters([
      GET_LOCAL_SETTING_DASHBOARD,
      GET_LOCAL_SETTING_IS_DARK_MODE,
    ]),
    isProduction(){ return process.env.NODE_ENV == 'production' },
    filteredCallActivity() {
      const call_activity = _.isArray(this.$store.state.common.call_activity.data) ? this.$store.state.common.call_activity.data : [];
      const filter_call_activity = call_activity.filter((activity) => {
        if (typeof activity.call_date != "string") return false;
        if (activity.call_type === "audio" && activity.status === "Missed" && this.filter.call_activity.audio.missed) return true;
        if (activity.call_type === "audio" && activity.status === "Dialed" && this.filter.call_activity.audio.dial) return true;
        if (activity.call_type === "audio" && activity.status === "Received" && this.filter.call_activity.audio.received) return true;
        if (activity.call_type === "video" && activity.status === "Missed" && this.filter.call_activity.video.missed) return true;
        if (activity.call_type === "video" && activity.status === "Dialed" && this.filter.call_activity.video.dial) return true;
        if (activity.call_type === "video" && activity.status === "Received" && this.filter.call_activity.video.received) return true;
        if (activity.call_type === "audio" && activity.status !== "Missed" && activity.soundFile && this.filter.call_activity.recording) return true;
        if (activity.call_type === "voicemail" && this.filter.call_activity.voicemail) return true;
        if (activity.call_type === "sms" && this.filter.call_activity.sms) return true;
        if (activity.call_type === "voicemail" && activity.is_shared != "no" && this.filter.call_activity.shared_voicemail) return true;
        if (activity.call_type === "audio" && (activity.dialType === "RINGGROUP" || activity.is_team) && this.filter.call_activity.teams) return true;
      });
      var currentDate = "";
      const array = _.orderBy(filter_call_activity,"date","desc")
      return array.map((activity) => {
        const date = activity.date;
        const call_date = moment.unix(date).format('MM-DD-YYYY')
        var isDateShow = call_date !== currentDate;
        currentDate = call_date !== currentDate ? call_date : currentDate
        return {
          ...activity,
          isDateShow: isDateShow,
          dateOnly: call_date,
        };
      })
    },
    newCallActivities(){
      const call_activity = this.response.call_activity;
      return _.orderBy(call_activity,"date","desc").map((activity,index,array)=>{
        const prev_activity = array[index-1]
        let date = activity.date
        if(prev_activity && moment(parseInt(activity.date)).isSame(moment(parseInt(prev_activity.date)),'day')) date = ''
        return {
          ...activity,
          own_date: date,
        }
      })
    },
  },
  filters: {
    formatDate(value) {
      if (!value) return "-";
      var ts = new Date(value);
      return ts.toDateString();
    },
    showTime,
    dateFormate(value){
      const test = value ? value : value
      return moment(test).calendar(null, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'DD MMM YYYY'
      });
    },
  },
  watch: {
    refresh_sec() {
      this.startTimer();
    },
    "$store.state.global_conditions.tab"(activeTab) {
      if (activeTab === "homeCopy") {
        this.fetchCallActivity();
      }
    },
  },
  methods: {
    ...mapMutations([
      SET_LOCAL_SETTING_DASHBOARD_DESIGN_CALL_INFO_WIDTH,
      SET_LOCAL_SETTING_DASHBOARD_DESIGN_FLIP,
      SET_LOCAL_SETTING_DASHBOARD_DESIGN_SCROLL_TOP,
      SET_LOCAL_SETTING_DASHBOARD_DESIGN_TAGS_SHOW,
      SET_LOCAL_SETTING_DASHBOARD_DESIGN_TRANSFER_LOGS,
      SET_LOCAL_SETTING_DASHBOARD_DESIGN_WIDTH,
    ]),
    deleteCallActivity(call) {
      let vm = this;
      this.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this voice mail`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api_sent.delete = call.id;
            VOIP_API.endpoints.voicemail.delete({
              id: call.id,
            }).then(() => {
              vm.$store.state.common.call_activity.data = vm.$store.state.common.call_activity.data.filter((item) => `${item.call_type}_${item.id}` !== `${call.call_type}_${call.id}`);
              vm.appNotify({
                message: "Successfully Deleted!",
                type: "success",
              })
            }).catch((error) => {
              vm.appNotify({
                message: _.get(error, "response.data.message") ||
                  "Something went wrong",
                type: "danger",
              })
            }).finally(() => {
              vm.api_sent.delete = false;
            });
          }
        },
      });
    },
    onScroll(Event) {
      const height = Event.target.scrollHeight;
      const top = Event.target.scrollTop;
      const offset_height = Event.target.offsetHeight;
      const scroll_bar_height = height - (height - offset_height);
      const scroll_bottom = Math.floor(height - (scroll_bar_height + top));
      // LOGGER.log('scroll_bottom',scroll_bottom,'scroll_bar_height',scroll_bar_height,'offset_height',offset_height,'top',top,'height',height,)
      if (scroll_bottom <= 2) {
        this.fetchCallActivity(false);
      }
    },
    async fetchCallActivity(start=true) {
      if(this.conditions.new_logs) {
        if (this.api.fetch_call_activity.send) return;
        if (this.pagination.call_activity.completed) return;
        try {
          this.api.fetch_call_activity.send=true
          if(start){
            this.pagination.call_activity.page = 1;
            this.pagination.call_activity.completed = false;
          }
          this.api.fetch_call_activity.send=true
          const { data } = await VOIP_API.endpoints.call_activity.newList({
            accountcode: this.$store.getters.getCurrentUser.account,
            page: this.pagination.call_activity.page,
            clean_data: 'yes',
          })
          const array = data?.data ?? []
          if(array.length==0){
            this.pagination.call_activity.completed = true;
          } else {
            if(start || this.pagination.call_activity.page==1) {
              this.pagination.call_activity.page = 2;
              this.response.call_activity = array;
            } else {
              this.pagination.call_activity.page = this.pagination.call_activity.page + 1;
              this.response.call_activity = _.concat(this.response.call_activity,array);
            }
          }
        } catch (ex) {
          this.pagination.call_activity.completed = true;
        } finally {
          this.api.fetch_call_activity.send=false
          const el = document.getElementById(`newLogsPhoneListScroll`)
          if(el && el.scrollHeight==el.offsetHeight) this.fetchCallActivity(false)
        }
      } else {
        LOGGER.log('fetchCallActivity',start)
        var that = this;
        if (this.api_sent.call_Activity) return;
        if(start){
          that.$store.state.common.call_activity.page = 1;
          that.$store.state.common.call_activity.is_completed = false;
        }
        if(that.$store.state.common.call_activity.is_completed) return;
        if(that.api_source.call_Activity){
          that.api_source.call_Activity.cancel('New Request')
        }
        that.api_source.call_Activity = require("axios").default.CancelToken.source();
        this.api_sent.call_Activity = true;
        try {
          const { data: res } = await VOIP_API.endpoints.call_activity.list(this.$store.getters.getCurrentUser?.account, {
            page: that.$store.state.common.call_activity.page,
            number: this.filter.call_activity.search,
          },that.api_source.call_Activity.token)
          const array = [
            ..._.isArray(res.data) ? res.data : Object.values(res.data),
            ..._.isArray(res.voicemails) ? res.voicemails : Object.values(res.voicemails),
          ];
          if(array.length==0){
            if(start) {
              that.$store.state.common.call_activity.data = []
            }
            that.$store.state.common.call_activity.is_completed = true;
          } else if (res.data) {
            that.$store.state.common.call_activity.total = res.total;
            const el = document.getElementById('phoneListScroll')
            if(start || that.$store.state.common.call_activity.page==1) {
              that.$store.state.common.call_activity.page = 2;
              that.$store.state.common.call_activity.data = array;
            } else {
              that.$store.state.common.call_activity.page = that.$store.state.common.call_activity.page + 1;
              that.$store.state.common.call_activity.data = _.concat(that.$store.state.common.call_activity.data,array);
            }
            if(el){
              setTimeout(()=>{
                if(that.GET_LOCAL_SETTING_DASHBOARD.design.scroll_top){
                  el.scrollTop = 0
                }
                if(el.scrollHeight==el.offsetHeight){
                  // console.log('running-894359')
                  that.fetchCallActivity(false)
                }
              }, 0.5 * 1000)
            }
          } else {
            that.$store.state.common.call_activity.is_completed = true;
          }
        } catch(ex){
          LOGGER.danger({ex})
          if(ex.message=='New Request'){
            //TODO
          } else {
            that.$store.state.common.call_activity.is_completed = true;
          }
        } finally {
          that.api_sent.call_Activity = false;
          that.api_source.call_Activity = null;
          // that.startTimer();
        }
      }
    },
    startTimer() {
      if (this.api_sent.moniter_state && this.api_sent.call_state) return;
      let vm = this;
      if (this.timer) {
        this.timer.stop();
      }
      if (this.refresh_sec == "0") return;
      this.timer = new Timer({
        tick: 1,
        ontick() {
          vm.time = vm.time - 1;
        },
        onstop() {
          // console.log("timer::stoped");
          vm.timer = null;
        },
        onend() {
          // console.log("timer::end");
          if (vm.timer) {
            vm.fetchCallActivity();
          }
          vm.timer = null;
        },
      });
      this.time = this.refresh_sec;
      this.timer.start(this.refresh_sec);
    },
    notificationTest(){
      let vm = this
      $fn.sleep(1 * 1000)
      .then(()=>{
        vm.appNotify({
          message: VOIP_API.errorMessages.server_down,
          type: 'danger',
        })
        vm.appNotify({
          message: VOIP_API.errorMessages.server_down,
          type: 'danger',
        })
        vm.appNotify({
          message: 'Please remove all the participants from the group first to delete.',
          type: 'success',
          duration: 50 * 1000,
        })
        vm.appNotify({
          message: `testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing testing`,
          title: 'Bill test',
          notify_type: 'alert',
          type: "danger",
          icon: 'https://api.kone.duplex.network/storage/defaults/logos/1448-1666696604559.webp',
          duration: 5 * 1000,
        })
      })
    },
    async testConfirmation(){
      this.$appConfirmation({
        title: 'Delete IVR?',
        message: 'Are you sure you want to delete this IVR service from your subscription?',
        variant: 'danger',
        alert: {
          variant: 'info',
          title: 'Warning',
          message: `After deletion, the IVR's configurations and functionalities will no longer be available`,
        },
        button: {
          no: 'Cancel',
          yes: 'Delete IVR',
        },
        // ok: true,
        callback: (/*{ confirm }*/) => {
          // console.log(confirm)
        }
      })
    },
    checkWindoWidth(){
      let ww = window.innerWidth
      this.windowInnerWidth = ww
      if(ww < 1700 && this.GET_LOCAL_SETTING_DASHBOARD.design.width!=25){
        this.compact = true
      }
      else{
        this.compact = false
      }
    },
    testCall() {
      this.$root.$emit(events.audio_call,{
        call_type: 'test',
      })
    },
    onSearchCallActivity: _.debounce(function(){
      this.fetchCallActivity(true);
    },2 * 1000),
    onFetchCallActivityInterval: _.debounce(function(){
      this.fetchCallActivity(true);
    },10 * 1000),
  },
  created(){
    if(!this.isCallScreen) {
      this.$root.$on(events.fetch_call_activity,this.fetchCallActivity)
      this.$root.$on(events.fetch_call_activity_interval,this.onFetchCallActivityInterval)
      
    }
    window.addEventListener('resize',this.checkWindoWidth)
    this.checkWindoWidth()
  },
  mounted() {
    this.loginUser = TokenService.USER.get();
    if(!this.isCallScreen){
      this.$store.state.common.call_activity.is_completed = false;
      this.fetchCallActivity();
    }
      window.addEventListener('resize',this.checkWindoWidth)
      this.checkWindoWidth()
  },
  beforeDestroy() {
    if(!this.isCallScreen) {
      this.$root.$off(events.fetch_call_activity,this.fetchCallActivity)
      this.$root.$on(events.fetch_call_activity_interval,this.onFetchCallActivityInterval)
    }
    if (this.timer) {
      this.timer.stop();
    }
    if(this.api_source.call_Activity){
      this.api_source.call_Activity.cancel('New Request')
    }
  },
}; 
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s;
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-10px);
  opacity: 0;
}
</style>
